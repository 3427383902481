<template>
    <div :class="[attrs.className != null ? attrs.className : '']"
        :style="[
            translate != null ? { translate } : '',
            attrs.heightElem != null ? !isNaN(attrs.heightElem) ? { height: attrs.heightElem + 'px' } : { height: attrs.heightElem } : '',
            attrs.widthElem != null ? !isNaN(attrs.widthElem) ? { width: attrs.widthElem + 'px' } : { width: attrs.widthElem } : '']" class="d-block"
        @click="clickElement($event)" :id="id">
        <!-- v-autoH="edit" -->
        <iframe v-autoH="edit" :style="[attrs.isPublish ? { pointerEvents: 'all' } : { pointerEvents: 'none' }]"
            style="display: block; height: 100%; width: 100%;" :src="getSRC + getURLParams" frameborder="0"></iframe>
    </div>
</template>
<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import interact from 'interactjs'
import vuetify from '../plugins/vuetify'
export default {
    props: ['attrs', 'id', 'listParent', 'edit'],
    vuetify,
    data() {
        return {
            isDragging: false,
            isSizing: false,
            type: 'IframeComponent'
        }
    },
    methods: {
        // onResize: function (e) {
        //     var h = e[0].target.style.height;
        //     if (h.includes('px')) {
        //         console.log('resize', e[0].target.style.height)
        //         console.log(this.id)
        //         this.updateAttrElement([{
        //             id: this.id,
        //             heightElem: h.split('px')[0]
        //         }, 'heightElem'])
        //     }
        // },
        clickElement: function (event) { // ELEMENT
            if (!this.attrs.isPublish) {
                this.isSelect()
                event.stopPropagation()
            }
        },
        isSelect: function (isDeselect = true) {
            if (!this.isDragging && !this.isSizing) {
                let elemTemp = this.getSelectElement
                if (elemTemp == null) { //Vacio
                    this.$el.classList.add('selected-element')
                    this.setSelectElement(this)
                    this.setIsContainer(false)
                    this.addListParent(this.listParent)
                    this.loadAttr()
                } else if (this.id == elemTemp.id) { //Deseleccion
                    if (isDeselect) {
                        this.$el.classList.remove('selected-element')
                        this.removeSelectElement()
                        this.setIsContainer(false)
                        this.removeListParent()
                        this.removeAttrElement()
                    }
                } else { //Otro seleccionado
                    elemTemp.$el.classList.remove('selected-element')
                    this.$el.classList.add('selected-element')
                    this.setSelectElement(this)
                    this.setIsContainer(false)
                    this.addListParent(this.listParent)
                    this.loadAttr()
                }
            }
        },
        loadAttr: function () {
            let objAttr = {
                src: this.attrs.src,
                src2: this.attrs.src2,
                heightElem: this.attrs.heightElem,
                widthElem: this.attrs.widthElem,
                axisX: this.attrs.axisX,
                axisY: this.attrs.axisY,
                type: 'embed',
                urlParamUser: this.attrs.urlParamUser
            }
            this.setAttrElement(objAttr)
        },
        dragMove: function (event) {
            this.isSelect(false)
            this.isDragging = true
            this.attrs.axisX += event.dx
            this.attrs.axisY += event.dy
            this.$emit("hoverParent", true)
        },
        dragEnd: function () {
            var parent = this.$el.parentNode
            let widthPx = (parent.clientWidth * parseFloat(this.attrs.axisX)) / 100
            let widthPercentage = (100 * widthPx) / parent.clientWidth + '%'
            this.attrs.axisX = widthPercentage
            this.$emit("hoverParent", false)
            this.updateAttrElement([{
                id: this.id,
                axisX: this.attrs.axisX
            }, 'axisX'])
            this.updateAttrElement([{
                id: this.id,
                axisY: this.attrs.axisY
            }, 'axisY'])
            let thisElement = this
            let elemTemp = this.getSelectElement
            if (elemTemp.id == this.id) {
                this.loadAttr()
            }
            setTimeout(() => { thisElement.isDragging = false }, 200)
        },

        ...mapMutations([
            'setIsContainer',
            'addListParent',
            'removeListParent',
            'addElements2ListParent',
            'removeElements2ListParent',
            'setSelectElement',
            'removeSelectElement',
            'addList2Add',
            'removeList2Add',
            'addElements2List2Add',
            'removeElements2List2Add',
            'setAttrElement',
            'removeAttrElement',
            'updateAttrElement'
        ])
    },
    mounted: function () {
        if (!this.attrs.isPublish) {
            var parent = this.$el.parentNode
            var element = this.$el

            // Auto H
            // var tmpRx = new ResizeObserver(this.onResize);
            // tmpRx.observe(element.firstChild);

            var elementThis = this
            if (element) {
                interact(element)
                    .resizable({
                        edges: { left: true, right: true, bottom: true, top: true },
                        listeners: {
                            move(event) {
                                elementThis.isSizing = true
                                var target = event.target
                                target.style.width = event.rect.width + 'px'
                                target.style.height = event.rect.height + 'px'
                            }
                        },
                        onend: function (event) {
                            var target = event.target
                            let widthPercentage = (100 * event.rect.width) / parent.clientWidth

                            target.style.width = widthPercentage + '%'
                            elementThis.updateAttrElement([{
                                id: elementThis.id,
                                widthElem: widthPercentage + '%'
                            }, 'widthElem'])
                            // elementThis.updateAttrElement([{
                            //     id: elementThis.id,
                            //     heightElem: event.rect.height
                            // }, 'heightElem'])
                            let elemTemp = elementThis.getSelectElement
                            if (elemTemp.id == elementThis.id) {
                                elementThis.loadAttr()
                            }
                            setTimeout(() => { elementThis.isSizing = false }, 200)
                        },
                        modifiers: [
                            interact.modifiers.restrictEdges({
                                outer: element.parentNode,
                            }),
                            interact.modifiers.restrictSize({
                                min: { width: 100, height: 50 }
                            })
                        ],
                        inertia: true
                    })
                    .draggable({
                        modifiers: [
                            interact.modifiers.snap({
                                targets: [
                                    interact.snappers.grid({ x: 10, y: 10 })
                                ],
                                range: Infinity,
                                relativePoints: [{ x: 0, y: 0 }]
                            }),
                            interact.modifiers.restrict({
                                restriction: element.parentNode,
                                elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
                                endOnly: true
                            })
                        ],
                        inertia: true
                    })
                    .on('dragmove', this.dragMove)
                    .on('dragend', this.dragEnd)
            }
        }
    },
    computed: {
        translate() {
            let ejeX = 0
            let ejeY = 0
            if (isNaN(this.attrs.axisX)) {
                ejeX = this.attrs.axisX
            } else {
                ejeX = this.attrs.axisX + 'px'
            }
            if (isNaN(this.attrs.axisY)) {
                ejeY = this.attrs.axisY
            } else {
                ejeY = this.attrs.axisY + 'px'
            }
            return `${ejeX} ${ejeY}`
        },
        getSRC() {
            console.log(this.attrs.src);
            try {
                if (this.userInit.id_role == '5') {
                    if (this.attrs.src2 != undefined) {
                        let tmp = this.attrs.src2[this.attrs.isLang ? this.attrs.isLang : 0].toString()
                        if (tmp.includes('http')) {
                            return tmp;
                        } else {
                            return window.location.origin + '/snippets/404.html';
                        }
                    } else {
                        let tmp = this.attrs.src[this.attrs.isLang ? this.attrs.isLang : 0].toString()
                        if (tmp.includes('http')) {
                            return this.attrs.src[this.attrs.isLang ? this.attrs.isLang : 0].toString();
                        } else {
                            return window.location.origin + '/snippets/404.html';
                        }
                    }
                } else {
                    let tmp = this.attrs.src[this.attrs.isLang ? this.attrs.isLang : 0].toString()
                    if (tmp.includes('http')) {
                        return this.attrs.src[this.attrs.isLang ? this.attrs.isLang : 0].toString();
                    } else {
                        return window.location.origin + '/snippets/404.html';
                    }
                }
            } catch (error) {
                return window.location.origin + '/snippets/404.html';
            }
        },
        getURLParams() {
            if (this.attrs.isPublish) {
                if (this.attrs.urlParamUser != '') {
                    var url = `?${this.attrs.urlParamUser}=${this.userInit.id_user}` + `&id_site=${this.$route.params.id_site}`;
                    if (window.location.search != '') {
                        url += `&${window.location.search.replace('?', '')}` + `&id_site=${this.$route.params.id_site}`;
                    }
                    console.log(url);
                    return url;

                } else {
                    if (window.location.search != '') {
                        return window.location.search + `&id_site=${this.$route.params.id_site}`;
                    } else {
                        return `?id_site=${this.$route.params.id_site}`;
                    }
                }
            }
            return `?id_site=${this.$route.params.id_site}`;
        },
        ...mapGetters([
            'getSelectElement',
            'getListElement',
            'getIsContainer',
            'getListElement2Add',
            'getAttrElement',
        ]),
        ...mapState([
            'userInit',
        ])
    },
}
</script>