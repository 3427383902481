<template>
    <v-main>
        <v-container>
            <v-row>
                <v-col offset="10" cols="2" class="align-center d-flex justify-center">
                    <v-fab-transition>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="accent" dark fab @click="loadDialog()" v-bind="attrs" v-on="on">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span class="font-12">Crear Sitio/Landing</span>
                        </v-tooltip>
                    </v-fab-transition>
                </v-col>
            </v-row>
            <v-row class="mt-4">
                <v-col cols="12" v-for="item in   listSites  " v-bind:key="item.id" class="my-3">
                    <v-card color="original" dark>
                        <v-row>
                            <v-col cols="10" class="justify-center">
                                <v-card-title class="text-h6 justify-center">
                                    {{ item.name }}
                                </v-card-title>
                                <v-card-subtitle class="justify-center">
                                    {{ item.description }}
                                </v-card-subtitle>
                                <v-chip label class="ma-2"
                                    :color="item.type == 'landing' ? 'orange darken-3' : 'teal darken-2'">{{ item.type
                                    }}</v-chip>
                                <v-chip label class="ma-2" v-if="item.type == 'site'"
                                    :color="item.status == 'develop' ? 'red accent-3' : 'green accent-3'"> {{ item.status ==
                                        'develop' ? 'Sin desplegar' : 'Desplegado' }}</v-chip>


                                <!-- <v-chip label link class="ma-2" color="accent" :to="'/site/' + item.id"
                                    v-if="item.status == 'deploy'"> Ver Site </v-chip> -->
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col cols="1" class="justify-center">
                                <v-card-buttons class="pa-3 align-center d-flex flex-column">

                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="white" icon :to="'/view/' + item.id + '/' + item.type"
                                                :disabled="item.settings == null">
                                                <v-icon dark v-bind="attrs" v-on="on">
                                                    mdi-application-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span class="font-12">Ver páginas</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="white" icon @click="loadDialogHeader(item.id)">
                                                <v-icon dark v-bind="attrs" v-on="on">
                                                    mdi-page-layout-header
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span class="font-12">Editar Cabezote</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="white" icon @click="loadDialogAuthStyle(item.id);">
                                                <v-icon dark v-bind="attrs" v-on="on">
                                                    mdi-lock
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span class="font-12">Personalizar autenticación</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="white" icon @click=" loadDialog(item.id)">
                                                <v-icon dark v-bind="attrs" v-on="on">
                                                    mdi-pencil
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span class="font-12">Editar información</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="white" icon v-if="item.type == 'site'"
                                                :to="'/user-sites/' + item.id">
                                                <!--  Otra Interfaz de Gestion de Usuarios -->
                                                <v-icon dark v-bind="attrs" v-on="on">
                                                    mdi-account-group
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span class="font-12">Gestión de Usuarios</span>
                                    </v-tooltip>


                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="white" icon v-if="item.type == 'site'"
                                                :to="'/business/' + item.id">
                                                <!--  Otra Interfaz de Gestion de Usuarios -->
                                                <v-icon dark v-bind="attrs" v-on="on">
                                                    mdi-office-building
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span class="font-12">Negocios</span>
                                    </v-tooltip>
                                </v-card-buttons>
                            </v-col>
                            <v-col cols="1" class="justify-center">
                                <v-card-buttons class="pa-3 align-center d-flex flex-column">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="white" icon @click=" loadDialogConfig(item.id)">
                                                <v-icon dark v-bind="attrs" v-on="on">
                                                    mdi-cog
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span class="font-12">Configuración</span>
                                    </v-tooltip>


                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn :color="item.status == 'develop' ? 'green accent-3' : 'red accent-3'"
                                                icon v-if="item.type == 'site'" @click=" deployOrStop(item.id)">
                                                <v-icon dark v-bind="attrs" v-on="on">
                                                    {{ item.status == 'develop' ? 'mdi-play-speed' : 'mdi-stop' }}
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span class="font-12"> {{ item.status == 'develop' ? 'Desplegar' : 'Detener'
                                        }}</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="white" icon v-if="item.type == 'site'"
                                                @click=" loadDialogDeploy(item.id)">
                                                <v-icon dark v-bind="attrs" v-on="on">
                                                    mdi-rocket-launch
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span class="font-12">Desplegar & actualizar</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="white" icon @click=" copySite(item.id)">
                                                <v-icon dark v-bind="attrs" v-on="on">
                                                    mdi-content-copy
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span class="font-12">Copiar</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="red" icon @click=" loadDialogDelete(item.id)">
                                                <v-icon dark v-bind="attrs" v-on="on">
                                                    mdi-delete-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span class="font-12">Eliminar Sitio/Landing</span>
                                    </v-tooltip>
                                </v-card-buttons>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <Overlay :overlay="overlay"></Overlay>
        <Dialog :dialog="dialog" :structureTable="structureSites" :stateName="nombreSite" @saveData="saveData">
        </Dialog>
        <DialogMsg :dialogDelete="dialogDelete" :stateName="nombreSite" @confirmDelete="confirmDelete"></DialogMsg>
        <DialogHeader :dialogHeader="dialogHeader" :structureTable="structureHeader" :stateName="nombreSite"
            @saveHeader="saveHeader"></DialogHeader>
        <DialogConfig :dialogSettings="dialogSettings" :structureTable="structureSetting" :stateName="nombreSite"
            @saveConfig="saveConfig"></DialogConfig>
        <!-- authStyleDialog -->
        <v-dialog v-model="authStyleDialog" persistent max-width="600">
            <v-card>
                <v-card-title class="text-h5">
                    Personalizar autenticación
                </v-card-title>

                <v-row no-gutters>
                    <v-col cols="12" sm="6">
                        <v-col class="shrink" style="max-width: 220px;">
                            <p class="mb-1">Color de titulo</p>
                            <v-text-field v-model="authColorTitle" v-mask="mask[0]" hide-details class="ma-0 pa-0" solo>
                                <template v-slot:append>
                                    <v-menu v-model="menu[0]" top nudge-bottom="105" nudge-left="16"
                                        :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div :style="swatchStyle(authColorTitle, menu[0])" v-on="on" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="authColorTitle" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>

                        <v-col class="shrink" style="max-width: 220px;">
                            <p class="mb-1">Color del botón</p>
                            <v-text-field v-model="authColorButton" v-mask="mask[1]" hide-details class="ma-0 pa-0" solo>
                                <template v-slot:append>
                                    <v-menu v-model="menu[1]" top nudge-bottom="105" nudge-left="16"
                                        :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div :style="swatchStyle(authColorButton, menu[1])" v-on="on" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="authColorButton" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-col class="shrink" style="max-width: 220px;">
                            <p class="mb-1">Color de texto</p>
                            <v-text-field v-model="authColorText" v-mask="mask[2]" hide-details class="ma-0 pa-0" solo>
                                <template v-slot:append>
                                    <v-menu v-model="menu[2]" top nudge-bottom="105" nudge-left="16"
                                        :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div :style="swatchStyle(authColorText, menu[2])" v-on="on" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="authColorText" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>

                        <v-col class="shrink" style="max-width: 220px;">
                            <p class="mb-1">Color de los enlances</p>
                            <v-text-field v-model="authColorLink" v-mask="mask[3]" hide-details class="ma-0 pa-0" solo>
                                <template v-slot:append>
                                    <v-menu v-model="menu[3]" top nudge-bottom="105" nudge-left="16"
                                        :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div :style="swatchStyle(authColorLink, menu[3])" v-on="on" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="authColorLink" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-col>
                </v-row>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click=" authStyleDialog = false" class="mr-4">
                        Cancelar
                    </v-btn>
                    <v-btn text @click=" saveAuthStyle()">
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Deployment Dialog -->
        <v-dialog v-model="deploymentDialog" persistent max-width="600">
            <v-card>
                <v-card-title class="text-h5">
                    Desplegar & actualizar
                </v-card-title>
                <v-row no-gutters class="px-3">
                    <v-col cols="12">
                        <v-select label="Negocio" v-model="business" :items="businessOptions" @change="updateAEService()"
                            item-text="text" item-value="value">
                        </v-select>
                        <v-text-field label="Servicio de App Engine" v-model="appEngineService"></v-text-field>
                    </v-col>
                </v-row>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click=" deploymentDialog = false" class="mr-4">
                        Cancelar
                    </v-btn>
                    <v-btn text @click="deploy()">
                        Desplegar
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-main>
</template>

<script>


import vuetify from '../plugins/vuetify'
import Overlay from '../components/Overlay.vue'
import Dialog from '../components/Dialog.vue'
import DialogMsg from '../components/DialogMsg.vue'
import DialogHeader from '../components/DialogHeader.vue'
import DialogConfig from '../components/DialogConfig.vue'
import { mapState } from 'vuex'

export default {
    name: 'Sites',
    vuetify,
    data: () => ({
        listSites: [],
        idUser: null,
        idSelected: null,
        nombreSite: null,
        overlay: false,
        dialog: false,
        dialogDelete: false,
        dialogSettings: false,
        dialogHeader: false,
        structureSites: [
            {
                "value": "id",
                "type": "string",
                "text": "Id",
                "required": false,
                "show": false,
                "data": "",
            },
            {
                "value": "name",
                "type": "string",
                "text": "Nombre",
                "required": true,
                "show": true,
                "data": "",
            },
            {
                "value": "description",
                "type": "string",
                "text": "Descripción",
                "required": true,
                "show": true,
                "data": "",
            },
            {
                "value": "type",
                "type": "select",
                "text": "Tipo",
                "required": true,
                "show": true,
                "data": "",
                "options": [
                    {
                        text: "Landing",
                        value: 'landing'
                    },
                    {
                        text: "Site",
                        value: 'site'
                    }
                ]
            }
        ],
        structureSetting: [
            {
                "value": "language",
                "type": "select",
                "multiple": true,
                "text": "Lenguajes",
                "required": false,
                "show": true,
                "data": "",
                "options": [
                    {
                        text: "Español",
                        value: 'spanish'
                    },
                    {
                        text: "Inglés",
                        value: 'english'
                    },
                    {
                        text: "Portugués",
                        value: 'portuguese'
                    }
                ]
            },
            {
                "value": "currency",
                "type": "select",
                "multiple": true,
                "text": "Moneda",
                "required": false,
                "show": true,
                "data": "",
                "options": [
                    {
                        text: "Peso Colombiano",
                        value: 'cop'
                    },
                    {
                        text: "Dólar",
                        value: 'usd'
                    },
                    {
                        text: "Real Brasileño",
                        value: 'brl'
                    }
                ]
            },
            {
                "value": "contact_mail",
                "type": "string",
                "text": "Correo Eléctronico de contacto",
                "required": false,
                "show": true,
                "data": "",
            },
            {
                "value": "social_media",
                "type": "select",
                "text": "Lista de Redes Sociales",
                "required": false,
                "show": true,
                "data": "",
                "options": [
                    {
                        text: "Twitter",
                        value: 'twitter'
                    },
                    {
                        text: "Instagram",
                        value: 'instagram'
                    }
                ]
            },
            {
                "value": "cookies",
                "type": "checkbox",
                "text": "Habilitar Cookies",
                "required": false,
                "show": true,
                "data": false,
            },
            {
                "value": "analytics",
                "type": "string",
                "text": "Código Google Analytics",
                "required": false,
                "show": true,
                "data": "",
            },
            {
                "value": "api",
                "type": "string",
                "text": "URL API de datos",
                "required": false,
                "show": true,
                "data": "",
            },
            {
                "value": "keyfile",
                "type": "file",
                "text": "Llave de cuenta de servicio (GCP)",
                "required": false,
                "show": true,
                "data": "",
            }
        ],
        structureHeader: [
            {
                "value": "text-color",
                "type": "color",
                "text": "Color Texto",
                "dialog": false,
                "show": true,
                "data": '#ff00ff',
            },
            {
                "value": "background-color",
                "type": "color",
                "text": "Color Fondo",
                "dialog": false,
                "show": true,
                "data": "#000000",
            },
            {
                "value": "active-color",
                "type": "color",
                "text": "Página activa",
                "dialog": false,
                "show": true,
                "data": "#000000",
            },
            {
                "value": "lang-background",
                "type": "color",
                "text": "Fondo del selector de idiomas",
                "dialog": false,
                "show": true,
                "data": "#B8B8B8",
            }
        ],
        authStyleDialog: false,
        deploymentDialog: false,
        authColorTitle: '#0080FFFF',
        authColorButton: '#00FF05FF',
        authColorText: '#8B8B8BFF',
        authColorLink: '#FF8800FF',
        mask: ['!#XXXXXXXX', '!#XXXXXXXX', '!#XXXXXXXX', '!#XXXXXXXX'],
        menu: [false, false, false, false],
        site: null,
        business: null,
        businessList: [],
        businessOptions: [],
        appEngineService: 'default'
    }),
    components: {
        Overlay,
        Dialog,
        DialogConfig,
        DialogHeader,
        DialogMsg
    },
    computed: {
        ...mapState([
            'urlProd',
            'urlTest',
            'isProd',
            'isLogin',
            'userInit'
        ]),

    },
    methods: {
        swatchStyle(color, menu) {
            return {
                backgroundColor: color,
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                borderRadius: menu ? '50%' : '4px',
                border: '1px solid',
                transition: 'border-radius 200ms ease-in-out'
            }
        },
        loadDialog(id) {
            if (id != null) {
                this.idSelected = id
                for (let i = 0; i < this.listSites.length; i++) {
                    if (this.listSites[i].id == id) {
                        for (let j = 0; j < Object.keys(this.listSites[i]).length; j++) {
                            for (let k = 0; k < this.structureSites.length; k++) {
                                if (this.structureSites[k]["value"] == Object.keys(this.listSites[i])[j]) {
                                    this.structureSites[k]["data"] = this.listSites[i][Object.keys(this.listSites[i])[j]]
                                }
                            }
                        }
                        this.nombreSite = this.listSites[i].name
                        break;
                    }
                }
            } else {
                for (let index = 0; index < this.structureSites.length; index++) {
                    this.structureSites[index]["data"] = '';
                }
            }
            this.dialog = true
        },
        loadDialogDelete(id) {
            if (id != null) {
                this.idSelected = id
                for (let i = 0; i < this.listSites.length; i++) {
                    if (this.listSites[i].id == id) {
                        this.nombreSite = this.listSites[i].name
                        break;
                    }
                }
            }
            this.dialogDelete = true
        },
        loadDialogConfig(id) {
            if (id != null) {
                this.idSelected = id
                for (let i = 0; i < this.listSites.length; i++) {
                    if (this.listSites[i].id == id) {
                        if (this.listSites[i]['settings'] != null) {
                            let keys = Object.keys(this.listSites[i]['settings'])
                            for (let j = 0; j < keys.length; j++) {
                                for (let k = 0; k < this.structureSetting.length; k++) {
                                    if (this.structureSetting[k]["value"] == keys[j]) {
                                        if (this.structureSetting[k]["type"] == 'select') {
                                            this.structureSetting[k]["data"] = this.listSites[i]['settings'][keys[j]].split(',')
                                        } else {
                                            this.structureSetting[k]["data"] = this.listSites[i]['settings'][keys[j]]
                                        }
                                    }
                                }
                            }
                        } else {
                            for (let index = 0; index < this.structureSetting.length; index++) {
                                this.structureSetting[index]["data"] = '';
                            }
                        }
                        this.nombreSite = this.listSites[i].name
                        break;
                    }
                }
            }
            this.dialogSettings = true
        },
        loadDialogHeader(id) {
            if (id != null) {
                this.idSelected = id
                for (let i = 0; i < this.listSites.length; i++) {
                    if (this.listSites[i].id == id) {
                        if (this.listSites[i]['header'] != null) {
                            let keys = Object.keys(this.listSites[i]['header'])
                            for (let j = 0; j < keys.length; j++) {
                                for (let k = 0; k < this.structureHeader.length; k++) {
                                    if (this.structureHeader[k]["value"] == keys[j]) {
                                        this.structureHeader[k]["data"] = this.listSites[i]['header'][keys[j]]
                                    }
                                }
                            }
                        } else {
                            for (let index = 0; index < this.structureHeader.length; index++) {
                                this.structureHeader[index]["data"] = '';
                            }
                        }
                        this.nombreSite = this.listSites[i].name
                        break;
                    }
                }
            }
            this.dialogHeader = true
        },
        loadDialogAuthStyle(id) {
            if (id != null) {
                this.idSelected = id
                for (let i = 0; i < this.listSites.length; i++) {
                    if (this.listSites[i].id == id) {
                        console.log(this.listSites[i]);
                        if (this.listSites[i]['authStyle'] != null) {

                            var tmpAuthStyle = JSON.parse(this.listSites[i]['authStyle'])
                            console.log(tmpAuthStyle);
                            this.authColorTitle = tmpAuthStyle['authColorTitle']
                            this.authColorText = tmpAuthStyle['authColorText']
                            this.authColorLink = tmpAuthStyle['authColorLink']
                            this.authColorButton = tmpAuthStyle['authColorButton']
                        }
                        break;
                    }
                }
            }
            this.authStyleDialog = true
        },
        async loadDialogDeploy(id) {
            this.businessOptions = [];
            this.site = id;
            this.deploymentDialog = true
            var url = this.isProd ? this.urlProd + 'api/business/get/' : this.urlTest + 'api/business/get/'
            let result = await fetch(`${url}${id}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((res) => res.json())
            this.businessList = result.data;
            result.data.forEach((n, i) => {
                this.businessOptions.push({
                    text: n.name,
                    value: n.id,
                })
                if (i == 0) {
                    if (n.domain_appengine == null) {
                        this.appEngineService = 'default'
                    } else {
                        this.appEngineService = (n.domain_appengine.includes('-dot-')) ? n.domain_appengine.split('-dot-')[0].split('//')[1] : 'default';
                    }
                }
            });
        },
        updateAEService() {
            this.businessList.forEach(n => {
                if (n.id == this.business) {
                    console.log(n.domain_appengine);
                    if (n.domain_appengine == null) {
                        this.appEngineService = 'default'
                    } else {
                        this.appEngineService = (n.domain_appengine.includes('-dot-')) ? n.domain_appengine.split('-dot-')[0].split('//')[1] : 'default';
                    }
                }
            })
        },
        async deploy() {
            this.overlay = true;
            let url = this.isProd ? this.urlProd + 'api/deploy' : this.urlTest + 'api/deploy'
            let result = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "id_site": this.site,
                    "id_business": this.business,
                    "service": this.appEngineService
                })
            }).then((res) => res.json())
            this.overlay = false;
            alert(result.msg);
            if (typeof result.data == 'object') {
                if (result.data['details'] != undefined) {
                    alert(result.data.details);
                } else {
                    if (result.data['code'] == 7) {
                        alert(`Access Not Configured. Cloud Build has not been used in project testgenerator-388414 before or it is disabled. Enable it by visiting https://console.developers.google.com/apis/api/cloudbuild.googleapis.com/overview?project=testgenerator-388414 then retry. If you enabled this API recently, wait a few minutes for the action to propagate to our systems and 
retry.`)
                    }
                }
            }
            console.log(result.data);
            this.deploymentDialog = false;
        },
        async deployOrStop(id) {
            if (id != null) {
                this.overlay = true
                this.idSelected = id
                for (let i = 0; i < this.listSites.length; i++) {
                    if (this.listSites[i].id == id) {
                        var data = {}
                        data['id_user'] = this.idUser
                        data['id'] = this.idSelected
                        if (this.listSites[i].status == '' || this.listSites[i].status == 'develop') {
                            data['status'] = 'deploy'
                        } else {
                            data['status'] = 'develop'
                        }
                        let url = this.isProd ? this.urlProd + 'api/sites/update-status' : this.urlTest + 'api/sites/update-status'
                        let result = await fetch(url, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(data)
                        }).then((res) => res.json())
                        if (result.success && result.code == 200) {
                            let result = await fetch(this.isProd ? this.urlProd + 'api/sites/user/' + this.idUser : this.urlTest + 'api/sites/user/' + this.idUser).then((res) => res.json())
                            this.overlay = false
                            if (result.success && result.code == 200) {
                                this.listSites = result.data
                                for (let i = 0; i < this.listSites.length; i++) {
                                    if (this.listSites[i].settings != null) {
                                        this.listSites[i].settings = JSON.parse(this.listSites[i].settings)
                                    }
                                    if (this.listSites[i].header != null) {
                                        this.listSites[i].header = JSON.parse(this.listSites[i].header)
                                    }
                                }
                            }
                        } else {
                            console.log('Ocurrio un error', result);
                        }
                    }
                }
            }
        },
        async confirmDelete(e) {
            if (e) {
                this.dialogDelete = false
                var data = {
                    id_user: this.idUser,
                    id: this.idSelected
                }
                this.overlay = true
                let url = this.isProd ? this.urlProd + 'api/sites/delete' : this.urlTest + 'api/sites/delete'
                let result = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then((res) => res.json())
                if (result.success && result.code == 200) {
                    let result = await fetch(this.isProd ? this.urlProd + 'api/sites/user/' + this.idUser : this.urlTest + 'api/sites/user/' + this.idUser).then((res) => res.json())
                    this.overlay = false
                    if (result.success && result.code == 200) {
                        this.listSites = result.data
                        for (let i = 0; i < this.listSites.length; i++) {
                            if (this.listSites[i].settings != null) {
                                this.listSites[i].settings = JSON.parse(this.listSites[i].settings)
                            }
                            if (this.listSites[i].header != null) {
                                this.listSites[i].header = JSON.parse(this.listSites[i].header)
                            }
                        }
                    }
                } else {
                    console.log('Ocurrio un error', result);
                }
            } else {
                this.dialogDelete = false
            }
        },
        async saveData(e) {
            if (e == null) {
                this.dialog = false
            } else {
                this.dialog = false
                var data = {}
                for (let i = 0; i < e.length; i++) {
                    if (e[i]['data'] != '') {
                        data[e[i]['value']] = e[i]['data']
                    }
                }
                data['id_user'] = this.idUser
                this.overlay = true
                let isCreateOrUpdate = !data.id ? '/create' : '/update'
                let url = this.isProd ? this.urlProd + 'api/sites' + isCreateOrUpdate : this.urlTest + 'api/sites' + isCreateOrUpdate
                let result = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then((res) => res.json())
                if (result.success && result.code == 200) {
                    let result = await fetch(this.isProd ? this.urlProd + 'api/sites/user/' + this.idUser : this.urlTest + 'api/sites/user/' + this.idUser).then((res) => res.json())
                    this.overlay = false
                    if (result.success && result.code == 200) {
                        this.listSites = result.data
                        for (let i = 0; i < this.listSites.length; i++) {
                            if (this.listSites[i].settings != null) {
                                this.listSites[i].settings = JSON.parse(this.listSites[i].settings)
                            }
                            if (this.listSites[i].header != null) {
                                this.listSites[i].header = JSON.parse(this.listSites[i].header)
                            }
                        }
                    }
                } else {
                    console.log('Ocurrio un error', result);
                    //TODO REALIZAR ALERT
                    this.overlay = false
                }
            }
        },
        async saveConfig(e) {
            console.log(e)
            if (e == null) {
                this.dialogSettings = false
            } else {
                this.dialogSettings = false
                var data = {}
                var settings = {}
                var file = null;
                for (let i = 0; i < e.length; i++) {
                    if (e[i]['data'] != '') {
                        if (e[i]['type'] == 'file') {
                            file = e[i]['data']
                        } {
                            if (Array.isArray(e[i]['data'])) {
                                settings[e[i]['value']] = e[i]['data'].join(',')
                            } else {
                                settings[e[i]['value']] = e[i]['data']
                            }
                        }
                    }
                }
                data['settings'] = JSON.stringify(settings)
                data['id_user'] = this.idUser
                data['id'] = this.idSelected
                console.log(file)

                var formData = new FormData();
                if (file != null) {
                    formData.append('keyfile', file);
                }
                // json2formdata
                Object.keys(data).forEach(k => {
                    formData.append(k, data[k]);
                })

                this.overlay = true
                let url = this.isProd ? this.urlProd + 'api/sites/update-settings' : this.urlTest + 'api/sites/update-settings'
                let result = await fetch(url, {
                    method: 'POST',
                    // headers: {
                    //     'Accept': 'application/json',
                    //     'Content-Type': 'application/json'
                    // },
                    // body: JSON.stringify(data)
                    body: formData
                }).then((res) => res.json())
                if (result.success && result.code == 200) {
                    let result = await fetch(this.isProd ? this.urlProd + 'api/sites/user/' + this.idUser : this.urlTest + 'api/sites/user/' + this.idUser).then((res) => res.json())
                    this.overlay = false
                    if (result.success && result.code == 200) {
                        this.listSites = result.data
                        for (let i = 0; i < this.listSites.length; i++) {
                            if (this.listSites[i].settings != null) {
                                this.listSites[i].settings = JSON.parse(this.listSites[i].settings)
                            }
                            if (this.listSites[i].header != null) {
                                this.listSites[i].header = JSON.parse(this.listSites[i].header)
                            }
                        }
                    }
                } else {
                    console.log('Ocurrio un error', result);
                }
            }
        },
        async saveHeader(e) {
            if (e == null) {
                this.dialogHeader = false
            } else {
                this.dialogHeader = false
                var data = {}
                var header = {}
                for (let i = 0; i < e.length; i++) {
                    if (e[i]['data'] != '') {
                        header[e[i]['value']] = e[i]['data']
                    }
                }
                data['header'] = JSON.stringify(header)
                data['id_user'] = this.idUser
                data['id'] = this.idSelected
                console.log(data)
                this.overlay = true
                let url = this.isProd ? this.urlProd + 'api/sites/update-header' : this.urlTest + 'api/sites/update-header'
                let result = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then((res) => res.json())
                if (result.success && result.code == 200) {
                    let result = await fetch(this.isProd ? this.urlProd + 'api/sites/user/' + this.idUser : this.urlTest + 'api/sites/user/' + this.idUser).then((res) => res.json())
                    this.overlay = false
                    if (result.success && result.code == 200) {
                        this.listSites = result.data
                        for (let i = 0; i < this.listSites.length; i++) {
                            if (this.listSites[i].settings != null) {
                                this.listSites[i].settings = JSON.parse(this.listSites[i].settings)
                            }
                            if (this.listSites[i].header != null) {
                                this.listSites[i].header = JSON.parse(this.listSites[i].header)
                            }
                        }
                    }
                } else {
                    console.log('Ocurrio un error', result);
                }
            }
        },
        async saveAuthStyle() {
            this.authStyleDialog = false

            var data = {}
            data['authStyle'] = JSON.stringify({
                authColorTitle: this.authColorTitle,
                authColorButton: this.authColorButton,
                authColorText: this.authColorText,
                authColorLink: this.authColorLink
            })
            data['id_user'] = this.idUser
            data['id'] = this.idSelected

            this.overlay = true
            let url = this.isProd ? this.urlProd + 'api/sites/update-authStyle' : this.urlTest + 'api/sites/update-authStyle'
            let result = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then((res) => res.json())
            if (result.success && result.code == 200) {
                let result = await fetch(this.isProd ? this.urlProd + 'api/sites/user/' + this.idUser : this.urlTest + 'api/sites/user/' + this.idUser).then((res) => res.json())
                this.overlay = false
                if (result.success && result.code == 200) {
                    this.listSites = result.data
                    for (let i = 0; i < this.listSites.length; i++) {
                        if (this.listSites[i].settings != null) {
                            this.listSites[i].settings = JSON.parse(this.listSites[i].settings)
                        }
                        if (this.listSites[i].authStyle != null) {
                            // this.listSites[i].authStyle = JSON.parse(this.listSites[i].authStyle)
                        }
                        if (this.listSites[i].header != null) {
                            this.listSites[i].header = JSON.parse(this.listSites[i].header)
                        }
                    }
                }
            } else {
                console.log('Ocurrio un error', result);
            }
        }
    },
    created: async function () {
        // if (this.isProd) {
        this.idUser = this.userInit.id
        // }
        //  else {
        //     this.idUser = 'a16505ba-f7f2-415a-8f80-b8e9bd36144b'
        // }
        // this.idUser = this.userInit.id
        // let result = await fetch('http://localhost:9000/api/sites/user/' + this.userInit.id).then((res) => res.json())
        this.overlay = true
        let result = await fetch(this.isProd ? this.urlProd + 'api/sites/user/' + this.idUser : this.urlTest + 'api/sites/user/' + this.idUser).then((res) => res.json())
        this.overlay = false
        if (result.success && result.code == 200) {
            this.listSites = result.data
            for (let i = 0; i < this.listSites.length; i++) {
                if (this.listSites[i].settings != null) {
                    this.listSites[i].settings = JSON.parse(this.listSites[i].settings)
                }
                if (this.listSites[i].header != null) {
                    this.listSites[i].header = JSON.parse(this.listSites[i].header)
                }
            }
        } else {
            console.log('Ocurrio un error', result);
        }
    },
    metaInfo() {
        return {
            title: `${this.$route.name}`,
            // meta: [
            //   { name: 'description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline },
            //   { property: 'og:title', content: this.userData.name + ' - Epiloge' },
            //   { property: 'og:site_name', content: 'Epiloge' },
            //   { property: 'og:description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline },
            //   { property: 'og:type', content: 'profile' },
            //   { property: 'og:url', content: 'https://epiloge.com/@' + this.userData.username },
            //   { property: 'og:image', content: this.aws_url + '/users/' + this.userData.profileurl + '-main.jpg' }
            // ]
        }
    },
}
</script>

<style>
.font-12 {
    font-size: 12px;
}
</style>