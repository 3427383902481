<template>
    <div class="container-element-snap" 
        :class="[
        attrs.className != null ? attrs.className : '',
        attrs.sizeCover != null && attrs.sizeCover != 'custom' ? attrs.sizeCover : '']" 
        style="white-space: pre-line;" 
        @click="clickContainer($event)"
        :style="[
            attrs.styleBackgroundType == 'imagen' ?  { backgroundImage: 'url(' + attrs.styleImageBackground + ')' } : { backgroundColor: attrs.styleColorBackground },
            attrs.sizeCover == 'custom' ? {height: attrs.heightElem + 'px'} : '']" :id="id">
        <component v-for="field in listElements" :key="field.id" :is="field.type" :id="field.id" :attrs="field.attrs" :listParent="listElements"  @hoverParent="hoverParent" @updateState="updateState"></component>
    </div>
</template>

<script>
import vuetify from '../plugins/vuetify'
import TextComponent from '../components/TextComponent.vue'
import EmbedComponent from '../components/EmbedComponent.vue'
import IframeComponent from '../components/IframeComponent.vue'
import ImgComponent from '../components/ImgComponent.vue'
import InputComponent from '../components/InputComponent.vue'
import NewsLetterComponent from '../components/NewsLetterComponent.vue'
import ButtonComponent from '../components/ButtonComponent.vue'
import { mapMutations, mapGetters } from 'vuex'

export default {
    props: ['attrs', 'id', 'listElements', 'listParent'],
    vuetify,
    components: {
        TextComponent,
        ImgComponent,
        EmbedComponent,
        IframeComponent,
        InputComponent,
        ButtonComponent,
        NewsLetterComponent
    },
    data() {
        return {
            type: 'SectionComponent'
            // styleColorBackground: this.attrs.styleColorBackground,
            // styleImageBackground: this.attrs.styleImageBackground,
            // sizeCover: this.attrs.sizeCover, //Three Sizes
            // className: this.attrs.className
        }
    },
    methods: {
        clickContainer: function (event) { // CONTAINER
            if (!this.attrs.isPublish) {
                this.isSelect()
                event.stopPropagation()
            }
        },
        isSelect: function () {
            let elemTemp = this.getSelectElement
            if (elemTemp == null) { //Vacio
                this.$el.classList.add('selected-element')
                this.setSelectElement(this)
                this.setIsContainer(true)
                this.addListParent(this.listParent)
                this.addList2Add(this.listElements)
                this.loadAttr()
            } else if (this.id == elemTemp.id) { //Deseleccion
                this.$el.classList.remove('selected-element')
                this.removeSelectElement()
                this.setIsContainer(false)
                this.removeListParent()
                this.removeList2Add()
                this.removeAttrElement()
            } else { //Otro seleccionado
                elemTemp.$el.classList.remove('selected-element')
                this.$el.classList.add('selected-element')
                this.setSelectElement(this)
                this.setIsContainer(true)
                this.addListParent(this.listParent)
                this.addList2Add(this.listElements)
                this.loadAttr()
            }
        },
        loadAttr: function () {
            let objAttr = {
                id: this.id,
                styleColorBackground: this.attrs.styleColorBackground,
                styleImageBackground: this.attrs.styleImageBackground,
                styleBackgroundType: this.attrs.styleBackgroundType,
                sizeCover: this.attrs.sizeCover,
                heightElem: this.attrs.heightElem
            }
            this.setAttrElement(objAttr)
        },
        hoverParent: function (isOrNo) {
            if (isOrNo) {
                this.$el.classList.add('hover-container')
            } else {
                this.$el.classList.remove('hover-container')
            }
        },
        updateState: function (objectStatusNew) {
            console.log(objectStatusNew)
            for (let i = 0; i < this.listElements.length; i++) {
                if (objectStatusNew.id == this.listElements[i]['id']) {
                    if (objectStatusNew.axisX) this.listElements[i]['attrs']['axisX'] = objectStatusNew.axisX
                    if (objectStatusNew.axisY) this.listElements[i]['attrs']['axisY'] = objectStatusNew.axisY
                    if (objectStatusNew.widthElem) this.listElements[i]['attrs']['widthElem'] = objectStatusNew.widthElem
                    if (objectStatusNew.heightElem) this.listElements[i]['attrs']['heightElem'] = objectStatusNew.heightElem
                }
            }
        },
        ...mapMutations([
            'setIsContainer',
            'addListParent',
            'removeListParent',
            'addElements2ListParent',
            'removeElements2ListParent',
            'setSelectElement',
            'removeSelectElement',
            'addList2Add',
            'removeList2Add',
            'addElements2List2Add',
            'removeElements2List2Add',
            'setAttrElement',
            'removeAttrElement',
        ])
    },
    computed: {
        ...mapGetters([
            'getSelectElement',
            'getListElement',
            'getIsContainer',
            'getListElement2Add',
            'getAttrElement',
        ])
    },
    watch: {
        // getAttrElement(newCount) {
        //     if(newCount && newCount.id == this.id) {
        //         console.log(newCount)
        //         this.styleColorBackground = newCount.styleColorBackground
        //         this.sizeCover = newCount.sizeCover
        //         this.styleTheme = newCount.styleTheme
        //         this.alignItems = newCount.alignItems
        //     }
        // },
    },
}
</script>

<style>
.small {
    height: 300px;
}

.medium {
    height: 500px;
}

.large {
    height: 700px;
}

.max {
    height: 100%;
}

.auto {
    height: auto;
}


.container-element-snap {
    cursor: pointer;
    position: relative;
}

.ispb {
    cursor: initial;
}

.hover-container {
    background-image: 
        repeating-linear-gradient(#cccccc2e 0 1px, transparent 1px 100%),
        repeating-linear-gradient(90deg, #cccccc2e 0 1px, transparent 1px 100%);
    background-size: 10px 10px;
    background-repeat: repeat;
}
</style>