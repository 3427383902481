import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Login from "../views/Login.vue";
import Reset from "../views/Reset.vue";
import Sites from "../views/Sites.vue";
import Users from "../views/Users.vue";
import Business from "../views/Business.vue";
import ViewPages from "../views/ViewPages.vue";
import EditPages from "../views/EditPages.vue";
import UsersSites from "../views/UsersSites.vue";
import Pg from "../views/Pg.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Gestión de Usuarios",
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sites",
    name: "Gestión de Sitios",
    component: Sites,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user-sites/:id",
    name: "Gestión de Usuario del Sitio",
    component: UsersSites,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/business/:id",
    name: "Gestión de Negocios",
    component: Business,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/view/:id/:type",
    name: "Ver Páginas",
    component: ViewPages,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pages-edit/:id",
    name: "Editor de Páginas",
    component: EditPages,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Inicio de Sesión",
    component: Login,
  },
  {
    path: "/pg/:id_site/:id_business/:dummy/:lang?",
    name: "Page",
    component: Pg,
    meta: {
      checkAuth: true
    },
  },
  {
    path: "/reset",
    name: "Crear contraseña",
    component: Reset,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let url = store.state.isProd
    ? store.state.urlProd + "api/getSessioned"
    : store.state.urlTest + "api/getSessioned";
  // if(store.state.isProd) {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (store.state.isLogin) {
      next()
    } else {
      let result = await fetch(url).then((res) => res.json())
      if (result.session.auth) {
        store.commit('isLogged')
        store.commit('saveDataLoggin', result.session.user)
      }
      if (store.state.isLogin) {
        next()
      } else {
        next('/login')
      }
    }
  } else if (to.matched.some(route => route.meta.checkAuth)) {
    let result = await fetch(url).then((res) => res.json())
    if (result.session.auth) {
      store.commit('isLogged')
      store.commit('saveDataLoggin', result.session.user)
    }
    next();
  } else {
    next();
  }
  // } else {
  //   next();
  // }
});

export default router;
