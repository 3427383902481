import Vue from 'vue'
import VueMeta from 'vue-meta'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import iframeResize from 'iframe-resizer/js/iframeResizer';

Vue.config.productionTip = false
Vue.config.silent = true

Vue.use(VueMeta)
// Vue.directive('autoH', {
//   bind: function (el, binding, vnode) {
//     if (binding.value == false) {
//       const store = vnode.context.$store;
//       if (store.getters.getAttrElement) {
//         console.log(store.getters.getAttrElement)
//       }
//       el.id = Date.now();
//       el.addEventListener('load', () => {
//         el.style.height = el.contentDocument.body.scrollHeight + 'px';
//         el.parentElement.style.height = el.contentDocument.body.scrollHeight + 'px';
//         // console.log('loaded iframe', el.style.height)
//         el.contentWindow.addEventListener('resize', () => {
//           // console.log(el.contentDocument.body.scrollHeight);
//           el.style.height = el.contentDocument.body.scrollHeight + 'px';
//           el.parentElement.style.height = el.contentDocument.body.scrollHeight + 'px';
//         })
//       })
//     }
//   }
// })

Vue.directive('autoH', {
  bind: function (el, binding, vnode) {
    if (binding.value == false) {
      const store = vnode.context.$store;
      el.addEventListener('load', () => {
        iframeResize(
          {
            log: false,
            onMessage: function (msg) {
              if (msg.message.action == 'openLogin') {
                store.commit('setLoginDialog', true);
                store.commit('setRedirectTo', (msg.message.redirectTo != undefined) ? msg.message.redirectTo : null);
              } else if (msg.message.action == 'redirectTo') {
                location.href = msg.message.redirectTo;
              } else if (msg.message.action == 'openModalSnippet') {
                store.commit('setSnippetDialog', true);
                var url = window.location.origin + `/snippets/${msg.message.data.lang}/modals/${msg.message.data.modal}.html${msg.message.data.URLParams}`;
                store.commit('setSnippetDialogURL', url);
              } else if (msg.message.action == 'closeModal') {
                store.commit('setSnippetDialog', false);
                store.commit('setSnippetDialogURL', null);
              } else if (msg.message.action == 'scrollTo') {
                document.getElementById('contenido').scrollTo(0, parseFloat(msg.message['posY']))
              }
            },
            onResized: function () {
              console.log(el.style.height);
              el.parentElement.style.height = el.style.height;
            }
          }, el)
      })
    }
  },
  unbind: function (el) {
    el.iFrameResizer.removeListeners();
  }
})


new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')

