<template>
    <div>
        <v-navigation-drawer v-model="drawer" absolute temporary>
            <v-list nav dense>
                <v-list-item-group v-model="group" active-class="soft--text text--accent-4">
                    <v-list-item to="/">
                        <v-list-item-icon>
                            <v-icon>mdi-account-multiple</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Usuarios</v-list-item-title>
                    </v-list-item>

                    <v-list-item to="/sites">
                        <v-list-item-icon>
                            <v-icon>mdi-application-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Sites</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar color="primary" dark>
            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
            <v-img height="80" contain max-width="150" src="../assets/logo_w.png" class="mx-5"></v-img>
            <v-spacer></v-spacer>
            <v-toolbar-title>{{ name }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-menu bottom min-width="400px" rounded offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn icon x-large v-on="on">
                        <v-avatar size="32"><img
                                src="https://source.boringavatars.com/beam/120/?colors=585d5d,e06f72,e7a17a,e4b17d,#d1cbc0"></v-avatar>
                    </v-btn>
                </template>
                <v-card>
                    <v-list nav dense>
                        <v-list-item-group v-model="group" active-class="soft--text text--accent-4">
                            <v-list-item>
                                <v-list-item-icon>
                                        <v-icon>mdi-account-circle</v-icon>
                                    </v-list-item-icon>
                                <v-list-item-content>
                                    
                                    <v-list-item-title>User</v-list-item-title>
                                    <v-list-item-subtitle>...</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item to="/perfil">
                                <v-list-item-icon>
                                    <v-icon>mdi-account</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Perfil</v-list-item-title>
                            </v-list-item>

                            <v-list-item href="/api/logout">
                                <v-list-item-icon>
                                    <v-icon>mdi-logout-variant</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Salir</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-menu>

        </v-app-bar>
</div>
</template>

<script>
import vuetify from '../plugins/vuetify'

export default {
    vuetify,
    props: ['name'],
    data: () => ({
        drawer: false,
        group: null
    }),
}
</script>