<template>
    <v-main>
        <v-app-bar id="appbar1" dense
            :color="headerSite['background-color'] != null ? headerSite['background-color'] : primary" style="width: 100%;"
            v-if="!menuResponsive">
            <v-toolbar-side-icon>
                <v-img class="mr-3" :src="logoPage" height="40px" width="60px" contain>
                </v-img>
            </v-toolbar-side-icon>
            <v-toolbar-title class="original--text"
                :style="[headerSite['text-color'] != null ? { color: headerSite['text-color'] + ' !important' } : '']"> {{
                    nameSite }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn text v-for="item in listPages" :key="item.id"
                :href="'/pg/' + item.id + '/' + item.id_business + '/' + item.url"
                :color="item.url == indexPage ? (headerSite['active-color'] != null ? headerSite['active-color'] : 'original') : headerSite['text-color'] != null ? headerSite['text-color'] : 'original'"
                :style="item.intoheader ? { 'display': 'flex' } : { 'display': 'none' }">

                {{ getTitle(item.id_page) }}
                <div v-if="item.url == indexPage"
                    style="width: 100%; background: white; height: 2px;position: absolute; bottom: 0; margin-bottom: -4px;">
                </div>
            </v-btn>
            <v-btn-toggle
                :style="{ background: (headerSite['lang-background'] != null ? headerSite['lang-background'] : 'grey') }"
                v-model="langSelected" rounded="0" divided class="ms-5">
                <v-btn x-small text v-for="item in languagesFiltered" :key="item.value" :value="item.value"
                    :color="headerSite['text-color'] != null ? headerSite['text-color'] : 'soft'"
                    @click="changeLanguage(item.value)">
                    {{ item.text }}
                </v-btn>
            </v-btn-toggle>
            <v-btn v-if="userInit.id_role != '5'" text color="white" @click="setLoginDg(true)">
                {{authText[langSelected]['signin']['title']}}
            </v-btn>
            <v-menu v-if="userInit.id_role == '5'" bottom min-width="400px" rounded offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn icon x-large v-on="on">
                        <v-icon>mdi-account-circle</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-list nav dense>
                        <v-list-item-group v-model="group" active-class="soft--text text--accent-4">
                            <v-list-item disabled>
                                <v-list-item-icon>
                                    <v-icon>mdi-account-circle</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>

                                    <v-list-item-title>{{ userInit.name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ userInit.email }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item :href="profilePath">
                                <v-list-item-icon>
                                    <v-icon>mdi-account</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Perfil</v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="logout()">
                                <v-list-item-icon>
                                    <v-icon>mdi-logout-variant</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Salir</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-menu>


        </v-app-bar>
        <!-- Mobile -->
        <v-app-bar id="appbar2" dense
            :color="headerSite['background-color'] != null ? headerSite['background-color'] : primary" style="width: 100%;"
            v-if="menuResponsive">

            <v-toolbar-side-icon>
                <v-img class="mr-3" :src="logoPage" height="40px" width="60px" contain>
                </v-img>
            </v-toolbar-side-icon>
            <v-toolbar-title class="original--text"
                :style="[headerSite['text-color'] != null ? { color: headerSite['text-color'] + ' !important' } : '']"> {{
                    nameSite }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-menu bottom min-width="400px" rounded offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn icon x-large v-on="on"
                        :color="headerSite['text-color'] != null ? headerSite['text-color'] : 'white'">
                        <v-icon>mdi-menu</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-list nav dense>
                        <v-list-item-group v-model="group" active-class="soft--text text--accent-4">
                            <v-list-item v-for="item in listPages" :key="item.id"
                                :href="'/pg/' + item.id + '/' + item.id_business + '/' + item.url"
                                :style="item.intoheader ? { 'display': 'flex' } : { 'display': 'none' }">
                                <v-list-item-title> {{ getTitle(item.id_page) }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="userInit.id_role != '5'" @click="setLoginDg(true)">
                                <v-list-item-title>{{authText[langSelected]['signin']['title']}}</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-btn-toggle
                                :style="{ background: (headerSite['lang-background'] != null ? headerSite['lang-background'] : 'grey') }"
                                v-model="langSelected" rounded="0" divided class="ms-5 mb-2 mt-3">
                                <v-btn x-small text v-for="item in languagesFiltered" :key="item.value" :value="item.value"
                                    :color="headerSite['text-color'] != null ? headerSite['text-color'] : 'soft'"
                                    @click="changeLanguage(item.value)">
                                    {{ item.text }}
                                </v-btn>
                            </v-btn-toggle>
                            <v-divider v-if="userInit.id_role == '5'"></v-divider>
                            <!--  -->
                            <v-list-item :href="profilePath" v-if="userInit.id_role == '5'">
                                <v-list-item-icon>
                                    <v-icon>mdi-account</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Perfil</v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="logout()" v-if="userInit.id_role == '5'">
                                <v-list-item-icon>
                                    <v-icon>mdi-logout-variant</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Salir</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-menu>
        </v-app-bar>
        <!-- Modals -->
        <div class="text-center">
            <v-dialog v-model="dialog" width="auto">
                <v-card>
                    <div class="auth-modal">
                        <img :src="logoAuthUp" height="90" style="object-fit: contain;">

                        <h2 class="t1" :style="{ color: (authStyle['authColorTitle'] || '#005c47') }">{{authText[langSelected]['signin']['title']}}</h2>
                        <v-form ref="form">
                            <v-text-field :style="{ color: (authStyle['authColorText'] || 'inherit') + ' !important' }"
                                v-model="email" :rules="emailRules" :label="authText[langSelected]['signin']['input1']" required>
                            </v-text-field>
                            <v-text-field :style="{ color: (authStyle['authColorText'] || 'inherit') + ' !important' }"
                                v-model="password" type="password" :rules="passwordRules" :label="authText[langSelected]['signin']['input2']" required>
                            </v-text-field>
                            <v-btn depressed class="btn white--text mt-2"
                                :style="{ background: (authStyle['authColorButton'] || '#005c47') + ' !important' }"
                                @click="authLogin">
                                {{authText[langSelected]['signin']['button']}}
                            </v-btn>
                        </v-form>
                        <p :style="{ color: (authStyle['authColorText'] || 'inherit') }">{{authText[langSelected]['signin']['text1']}} <a
                                :style="{ color: (authStyle['authColorLink'] || '#6aaa6b') }"
                                @click="setLoginDg(false); dialog3 = true;">{{authText[langSelected]['signin']['link1']}}</a></p>
                        <p :style=" { color: (authStyle['authColorText'] || 'inherit') } ">{{authText[langSelected]['signin']['text2']}} <a
                                :style=" { color: (authStyle['authColorLink'] || '#6aaa6b') } "
                                @click=" setLoginDg(false); dialog4 = true; ">{{authText[langSelected]['signin']['link2']}}</a></p>
                        <img :src=" logoAuthDown " height="90" style="object-fit: contain;">
                    </div>
                </v-card>
            </v-dialog>
            <v-dialog v-model=" dialog2 " width="auto">
                <v-card>
                    <div class="auth-modal">
                        <img :src=" logoAuthUp " height="90" style="object-fit: contain;">
                        <h2 class="t1" :style=" { color: (authStyle['authColorTitle'] || '#005c47') } ">{{authText[langSelected]['reset']['title']}}</h2>
                        <v-form v-if=" validToken " ref="form">
                            <v-text-field :style=" { color: (authStyle['authColorText'] || 'inherit') + ' !important' } "
                                v-model=" password " type="password" :rules=" passwordRules " :label="authText[langSelected]['reset']['text1']"
                                required>
                            </v-text-field>
                            <v-text-field :style=" { color: (authStyle['authColorText'] || 'inherit') + ' !important' } "
                                v-model=" password1 " type="password" :rules=" passwordRules " :label="authText[langSelected]['reset']['text2']"
                                required>
                            </v-text-field>
                            <v-btn :style=" { background: (authStyle['authColorButton'] || '#005c47') + ' !important' } "
                                depressed class="btn white--text mt-2" @click=" authReset ">
                                {{authText[langSelected]['reset']['button']}}
                            </v-btn>
                            <div v-if=" errorPsw " class="py-2">
                                <p style="color: red;">{{authText[langSelected]['reset']['error1']}}</p>
                            </div>
                        </v-form>
                        <div v-if=" !validToken ">
                            <v-alert color="red" outlined class="my-5">
                                {{authText[langSelected]['reset']['error2']}}
                            </v-alert>
                        </div>
                        <img :src=" logoAuthDown " height="90" style="object-fit: contain;">
                    </div>
                </v-card>
            </v-dialog>
            <v-dialog v-model=" dialog3 " width="auto">
                <v-card>
                    <div class="auth-modal">
                        <img :src=" logoAuthUp " height="90" style="object-fit: contain;">
                        <h2 class="t1" :style=" { color: (authStyle['authColorTitle'] || '#005c47') } ">{{authText[langSelected]['signup']['title']}}
                        </h2>
                        <v-form ref="form">
                            <v-text-field :style=" { color: (authStyle['authColorText'] || 'inherit') + ' !important' } "
                                v-model=" nombre " :rules=" passwordRules " :label="authText[langSelected]['signup']['input1']" required>
                            </v-text-field>
                            <v-text-field :style=" { color: (authStyle['authColorText'] || 'inherit') + ' !important' } "
                                v-model=" email " :rules=" emailRules " :label="authText[langSelected]['signup']['input2']" required>
                            </v-text-field>
                            <v-text-field :style=" { color: (authStyle['authColorText'] || 'inherit') + ' !important' } "
                                v-model=" pais " :rules=" passwordRules " :label="authText[langSelected]['signup']['input3']" required>
                            </v-text-field>
                            <v-text-field :style=" { color: (authStyle['authColorText'] || 'inherit') + ' !important' } "
                                v-model=" password " type="password" :rules=" passwordRules " autocomplete="new-password"
                                :label="authText[langSelected]['signup']['input4']" required>
                            </v-text-field>
                            <v-btn :style=" { background: (authStyle['authColorButton'] || '#005c47') + ' !important' } "
                                depressed class="btn white--text mt-2" @click=" authCreate ">
                                {{authText[langSelected]['signup']['button']}}
                            </v-btn>
                        </v-form>
                        <p :style=" { color: (authStyle['authColorText'] || 'inherit') + ' !important' } ">{{authText[langSelected]['signup']['text1']}} <a :style=" { color: (authStyle['authColorLink'] || '#6aaa6b') } "
                                @click=" dialog3 = false; setLoginDg(true); ">{{authText[langSelected]['signup']['link1']}}</a></p>
                        <img :src=" logoAuthDown " height="90" style="object-fit: contain;">
                    </div>
                </v-card>
            </v-dialog>
            <v-dialog v-model=" dialog4 " width="auto">
                <v-card>
                    <div class="auth-modal">
                        <img :src=" logoAuthUp " height="90" style="object-fit: contain;">
                        <h2 class="t1" :style=" { color: (authStyle['authColorTitle'] || '#005c47') } ">{{authText[langSelected]['recover']['title']}}
                        </h2>
                        <v-form ref="form">
                            <v-text-field :style=" { color: (authStyle['authColorText'] || 'inherit') + ' !important' } "
                                v-model=" email " :rules=" emailRules " :label="authText[langSelected]['recover']['input1']" required>
                            </v-text-field>
                            <v-btn :style=" { background: (authStyle['authColorButton'] || '#005c47') + ' !important' } "
                                depressed class="btn white--text mt-2" @click=" authRecover ">
                                {{authText[langSelected]['recover']['button']}}
                            </v-btn>
                        </v-form>
                        <p><a :style=" { color: (authStyle['authColorLink'] || '#6aaa6b') } "
                                @click=" dialog4 = false; setLoginDg(true); ">{{authText[langSelected]['recover']['link1']}}</a></p>
                        <img :src=" logoAuthDown " height="90" style="object-fit: contain;">
                    </div>
                </v-card>
            </v-dialog>
            <v-dialog v-model=" snippetDialog ">
                <iframe v-autoH=" false "
                    :src=" snippetDialogURL + (snippetDialogURL != null ? (snippetDialogURL.includes('?') ? `&id_site=${$route.params.id_site}` : `?id_site=${$route.params.id_site}`) : '') "
                    frameborder="0" style="width: 100%;"></iframe>
            </v-dialog>
        </div>
        <v-container id="contenido" v-if=" defaultPage == null " fluid class="pa-0 container-element">
            <component v-for="       field        in        template       " v-bind:is=" field.type " :key=" field.id "
                v-bind:idElement=" field.id " v-bind:attrs=" field.attrs " v-bind:ispb=" field.isPublish "
                v-bind:listElements=" field.listElements " v-bind:edit=" false ">
            </component>
        </v-container>
        <v-container v-if=" defaultPage == 'signin' " fill-height>
            <div class="d-flex text-center flex-column flex-md-row flex-grow-1">
                <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
                    <div class="layout-content ma-auto w-full">
                        <v-card class="text-center pa-5" min-width="400">
                            <v-img :src=" logoPage " max-height="75px" max-width="400" contain></v-img>
                            <v-card-title class="justify-center text-h5 mb-2">
                                Iniciar sesión
                            </v-card-title>

                            <v-form class="form" ref="form">
                                <v-text-field v-model=" email " :rules=" emailRules " label="Correo electrónico" required>
                                </v-text-field>
                                <v-text-field v-model=" password " type="password" :rules=" passwordRules "
                                    label="Contraseña" required>
                                </v-text-field>
                                <v-btn depressed block color="soft" class="white--text mt-2" @click=" login ">
                                    Acceder
                                </v-btn>
                            </v-form>

                            <v-card-actions>
                                <v-btn text class="soft--text" href="./signup">
                                    Crear una cuenta
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </div>
                </div>
                <v-snackbar v-model=" snackbar " color="soft" :timeout=" timeout "> {{ textSnackBar }}
                    <template v-slot:action=" { attrs } ">
                        <v-btn color="blue" text v-bind=" attrs " @click=" snackbar = false ">
                            <v-icon color="secondary">mdi-close</v-icon>
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-overlay :value=" overlay " z-index="300" opacity="0.95">
                    <v-progress-circular indeterminate color="original" size="120">
                        <v-img src="../assets/icon_color.png" max-height="80" contain></v-img>
                    </v-progress-circular>
                </v-overlay>
            </div>
        </v-container>
        <!-- Crear una cuenta -->
        <v-container v-if=" defaultPage == 'signup' " fill-height>
            <div class="d-flex text-center flex-column flex-md-row flex-grow-1">
                <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
                    <div class="layout-content ma-auto w-full">
                        <v-card class="text-center pa-5" min-width="400">
                            <v-img :src=" logoPage " max-height="75px" max-width="400" contain></v-img>
                            <v-card-title class="justify-center text-h5 mb-2">
                                Crear una cuenta
                            </v-card-title>
                            <v-card-text class="text--primary">
                                <v-form ref="form">
                                    <v-text-field v-model=" name " :rules=" nameRules " label="Nombres" required>
                                    </v-text-field>
                                    <v-text-field v-model=" email " :rules=" emailRules " label="Correo electrónico"
                                        required>
                                    </v-text-field>
                                    <v-text-field v-model=" password " type="password" :rules=" passwordRules "
                                        label="Contraseña" required>
                                    </v-text-field>
                                    <v-btn depressed block color="soft" class="white--text mt-2" @click=" login ">
                                        Registrarme
                                    </v-btn>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn text class="soft--text" href="./signin">
                                    Iniciar sesión
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </div>
                </div>
                <v-snackbar v-model=" snackbar " color="soft" :timeout=" timeout "> {{ textSnackBar }}
                    <template v-slot:action=" { attrs } ">
                        <v-btn color="blue" text v-bind=" attrs " @click=" snackbar = false ">
                            <v-icon color="secondary">mdi-close</v-icon>
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-overlay :value=" overlay " z-index="300" opacity="0.95">
                    <v-progress-circular indeterminate color="original" size="120">
                        <v-img src="../assets/icon_color.png" max-height="80" contain></v-img>
                    </v-progress-circular>
                </v-overlay>
            </div>
        </v-container>
        <Overlay :overlay=" overlay "></Overlay>
        <v-snackbar v-model=" snackbar " color="soft" :timeout=" timeout "> {{ textSnackBar }}
            <template v-slot:action=" { attrs } ">
                <v-btn color="blue" text v-bind=" attrs " @click=" snackbar = false ">
                    <v-icon color="secondary">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-main>
</template>

<style>
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #fff;
}

::-webkit-scrollbar-thumb {
    background: #5b5656;
}

::-webkit-scrollbar-thumb:hover {
    background: #00b7fd;
}

.auth-modal {
    padding: 16px 32px;
    text-align: center;
    color: #757474;
}

.auth-modal img {
    max-width: 200px;
}

.auth-modal .t1 {
    color: #005c47;
}

.auth-modal form {
    margin-bottom: 32px;
    margin-top: 32px;
}

.auth-modal a {
    text-decoration: none;
    color: #6aaa6b;
}

.auth-modal .btn {
    background: #005c47;
}

.theme--light.v-label {
    color: inherit;
}
</style>

<script>
//TODO  
import vuetify from '../plugins/vuetify'
import SectionComponent from '../components/SectionComponent.vue'
import TextComponent from '../components/TextComponent.vue'
import ImgComponent from '../components/ImgComponent.vue'
import EmbedComponent from '../components/EmbedComponent.vue'
import IframeComponent from '../components/IframeComponent.vue'
import ButtonComponent from '../components/ButtonComponent.vue'
import InputComponent from '../components/InputComponent.vue'
import NewsLetterComponent from '../components/NewsLetterComponent.vue'
import Overlay from '../components/Overlay.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
    name: 'PagePublish',
    vuetify,
    data: () => ({
        overlay: false,
        listPages: [],
        indexPage: "",
        nameSite: null,
        languages: [{
            text: 'spa',
            value: 'spanish',
            status: false
        },
        {
            text: 'eng',
            value: 'english',
            status: false
        },
        {
            text: 'por',
            value: 'portuguese',
            status: false
        }],
        langSelected: 'spanish',
        settingsSite: {},
        headerSite: {},
        authStyle: {},
        idPage: null,
        namePage: "",
        logoPage: "",
        nameBusiness: "",
        template: null,
        defaultPage: null,
        favicon: '',
        // Auth
        logoAuthUp: '',
        logoAuthDown: '',
        emailRules: [
            v => !!v || 'Es obligatorio',
            v => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(v) || 'Debe ser un correo valido'
            }
        ],
        passwordRules: [
            v => !!v || 'Es obligatorio'
        ],
        nombre: '',
        pais: '',
        email: '',
        password: '',
        password1: '',
        dialog: false,
        dialog2: false,
        dialog3: false,
        dialog4: false,
        snippetDialog: false,
        snippetDialogURL: null,
        token: '',
        validToken: true,
        errorPsw: false,
        timeout: 2000,
        profilePath: '',
        menuResponsive: false,
        menuOverflow: null,
        menuListening: false,
        authText: {
            'spanish': {
                'signin': {
                    'title': 'Iniciar sesión',
                    'input1': 'Correo electrónico',
                    'input2': 'Contraseña',
                    'button': 'Iniciar sesión',
                    'text1': '¿No tienes una cuenta?',
                    'text2': '¿Olvidaste tu contraseña?',
                    'link1': 'Crea una cuenta',
                    'link2': 'Restablecer contraseña'
                },
                'signup': {
                    'title': 'Crear una cuenta',
                    'input1': 'Nombre',
                    'input2': 'Correo electrónico',
                    'input3': 'País',
                    'input4': 'Contraseña',
                    'button': 'CREAR',
                    'text1': '¿Ya tienes una cuenta?',
                    'link1': 'Inicia sesión',
                },
                "recover": {
                    'title': 'Recuperar contraseña',
                    'input1': 'Correo electrónico',
                    'button': 'RECUPERAR',
                    'link1': 'Volver al inicio de sesión'
                },
                "reset": {
                    'title': 'Asignar contraseña',
                    'input1': 'Nueva contraseña',
                    'input2': 'Confirmar contraseña',
                    'button': 'Continuar',
                    'error1': 'Las contraseñas no coinciden',
                    'error2': 'El enlace no es válido o ha caducado.'
                }
            },
            'english': {
                "signin": {
                    "title": "Sign in",
                    "input1": "Email",
                    "input2": "Password",
                    "button": "Sign in",
                    "text1": "Don't have an account?",
                    "text2": "Forgot your password?",
                    "link1": "Create an account",
                    "link2": "Reset password"
                },
                "signup": {
                    "title": "Create an account",
                    "input1": "Name",
                    "input2": "Email",
                    "input3": "Country",
                    "input4": "Password",
                    "button": "CREATE",
                    "text1": "Already have an account?",
                    "link1": "Sign in"
                },
                "recover": {
                    'title': 'Recover password',
                    'input1': 'Email',
                    'button': 'RECOVER',
                    'link1': 'Back to sign in'
                },
                'reset': {
                    'title': 'Assign password',
                    'input1': 'New password',
                    'input2': 'Confirm password',
                    'button': 'Continue',
                    'error1': 'Passwords do not match',
                    'error2': 'The link is invalid or has expired.'
                }
            },
            'portuguese': {
                "signin": {
                    "title": "Iniciar sessão",
                    "input1": "Endereço de email",
                    "input2": "Senha",
                    "button": "Iniciar sessão",
                    "text1": "Não tem uma conta?",
                    "text2": "Esqueceu sua senha?",
                    "link1": "Criar uma conta",
                    "link2": "Redefinir senha"
                },
                "signup": {
                    "title": "Criar uma conta",
                    "input1": "Nome",
                    "input2": "Endereço de email",
                    "input3": "País",
                    "input4": "Senha",
                    "button": "CRIAR",
                    "text1": "Já tem uma conta?",
                    "link1": "Iniciar sessão"
                },
                "recover": {
                    'title': 'Recuperar senha',
                    'input1': 'Endereço de email',
                    'button': 'RECUPERAR',
                    'link1': 'Voltar para a página de login'
                },
                'reset': {
                    'title': 'Definir senha',
                    'input1': 'Nova senha',
                    'input2': 'Confirmar nova senha',
                    'button': 'Continuar',
                    'error1': 'As senhas não coincidem',
                    'error2': 'O link não é válido ou expirou.'
                }
            }
        }
    }),
    components: {
        Overlay,
        SectionComponent,
        TextComponent,
        ImgComponent,
        EmbedComponent,
        IframeComponent,
        InputComponent,
        ButtonComponent,
        NewsLetterComponent
    },
    methods: {
        loadPage() {
            if (this.defaultPage != null) {
                // Copiar primera página
                this.headerSite = JSON.parse(this.listPages[0].header);
                this.settingsSite = JSON.parse(this.listPages[0].settings)
                this.authStyle = JSON.parse(this.listPages[0].authStyle)
            }

            for (let i = 0; i < this.listPages.length; i++) {
                if (this.listPages[i].url == this.indexPage) {
                    this.template = JSON.parse(this.listPages[i].structure)
                    this.nameSite = this.listPages[i].name_site
                    this.idPage = this.listPages[i].id_page
                    this.namePage = this.listPages[i].name_page
                    this.logoPage = this.listPages[i].logo
                    this.nameBusiness = this.listPages[i].name_business
                    this.favicon = this.listPages[i].favicon;

                    // Auth
                    this.logoAuthUp = this.listPages[i].logo_auth_up;
                    this.logoAuthDown = this.listPages[i].logo_auth_down;

                    if (this.listPages[i].settings != null) {
                        this.settingsSite = JSON.parse(this.listPages[i].settings)
                        if (this.settingsSite.language) this.settingsSite.language = this.settingsSite.language.split(',')
                        if (this.settingsSite.currency) this.settingsSite.currency = this.settingsSite.currency.split(',')
                    }

                    if (this.listPages[i].header != null) {
                        this.headerSite = JSON.parse(this.listPages[i].header)
                    }

                    if (this.listPages[i].authStyle != null) {
                        this.authStyle = JSON.parse(this.listPages[i].authStyle)
                    }

                }
            }
            if (this.nameSite == null) {
                for (let i = 0; i < this.listPages.length; i++) {
                    if (this.listPages[i].init) {
                        this.template = JSON.parse(this.listPages[i].structure)
                        this.nameSite = this.listPages[i].name_site
                        this.idPage = this.listPages[i].id_page
                        this.namePage = this.listPages[i].name_page
                        this.logoPage = this.listPages[i].logo
                        this.nameBusiness = this.listPages[i].name_business
                        break;
                    }

                    if (this.listPages[i].settings != null) {
                        this.settingsSite = JSON.parse(this.listPages[i].settings)
                        if (this.settingsSite.language) this.settingsSite.language = this.settingsSite.language.split(',')
                        if (this.settingsSite.currency) this.settingsSite.currency = this.settingsSite.currency.split(',')
                    }

                    if (this.listPages[i].header != null) {
                        this.headerSite = JSON.parse(this.listPages[i].header)
                    }


                    if (this.listPages[i].authStyle != null) {
                        this.authStyle = JSON.parse(this.listPages[i].authStyle)
                    }
                }
            }
            for (let i = 0; i < this.settingsSite.language.length; i++) {
                for (let l = 0; l < this.languages.length; l++) {
                    if (this.settingsSite.language[i] == this.languages[l].value) {
                        this.languages[l].status = true
                    }
                }
            }



            if (this.defaultPage != null) {
                if (this.listPages[0].settings != null) {
                    this.settingsSite = JSON.parse(this.listPages[0].settings)
                    if (this.settingsSite.language) this.settingsSite.language = this.settingsSite.language.split(',')
                    if (this.settingsSite.currency) this.settingsSite.currency = this.settingsSite.currency.split(',')
                }
                for (let i = 0; i < this.settingsSite.language.length; i++) {
                    for (let l = 0; l < this.languages.length; l++) {
                        if (this.settingsSite.language[i] == this.languages[l].value) {

                            this.languages[l].status = true
                        }
                    }
                }
            }

            // this.languages = this.settingsSite.language
            for (let i = 0; i < this.template.length; i++) {
                this.template[i].attrs.isPublish = true
                for (let l = 0; l < this.settingsSite.language.length; l++) {
                    if (this.settingsSite.language[l].includes(this.langSelected)) {
                        this.template[i].attrs.isLang = l
                    }
                }
                if (this.template[i].listElements) {
                    for (let j = 0; j < this.template[i].listElements.length; j++) {
                        if (this.template[i].listElements[j].attrs.logo) {
                            this.template[i].listElements[j].attrs.src = this.logoPage
                        }
                        this.template[i].listElements[j].attrs.isPublish = true
                        for (let l = 0; l < this.settingsSite.language.length; l++) {
                            if (this.settingsSite.language[l].includes(this.langSelected)) {
                                this.template[i].listElements[j].attrs.isLang = l
                            }
                        }
                    }
                }
            }

            this.overlay = false
        },
        getTitle(id_page) {
            var titulo = ' ';
            this.listPages.forEach(item => {
                if (item.id_page == id_page) {
                    if (this.langSelected == 'spanish') {
                        titulo = item.title;
                    } else if (this.langSelected == 'english') {
                        titulo = item.title2;
                    } else if (this.langSelected == 'portuguese') {
                        titulo = item.title3;
                    }
                }
            })
            return titulo;
        },
        changeLanguage(lang) {
            this.langSelected = lang
            localStorage.setItem('lang', lang);
            let exampleArray = JSON.parse(JSON.stringify(this.template));
            for (let i = 0; i < exampleArray.length; i++) {
                for (let l = 0; l < this.settingsSite.language.length; l++) {
                    if (this.settingsSite.language[l].includes(this.langSelected)) {
                        exampleArray[i].attrs.isLang = l
                    }
                }
                if (exampleArray[i].listElements) {
                    for (let j = 0; j < exampleArray[i].listElements.length; j++) {
                        if (exampleArray[i].listElements[j].attrs.logo) {
                            exampleArray[i].listElements[j].attrs.src = this.logoPage
                        }
                        for (let l = 0; l < this.settingsSite.language.length; l++) {
                            if (this.settingsSite.language[l].includes(this.langSelected)) {
                                exampleArray[i].listElements[j].attrs.isLang = l
                            }
                        }
                    }
                }
            }
            this.template = JSON.parse(JSON.stringify(exampleArray));
        },
        async authLogin() {
            var self = this;
            var data = {
                email: this.email,
                password: this.password,
                id_site: this.$route.params.id_site
            };
            this.overlay = true
            var result = await fetch(this.isProd ? this.urlProd + 'api/logout' : this.urlTest + 'api/logout', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((res) => res.json()).then(() => {
                self.isLogout();
            })

            result = await fetch(this.isProd ? this.urlProd + 'api/login' : this.urlTest + 'api/login', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then((res) => res.json())


            if (result.length > 0) {
                this.isLogged();
                this.saveDataLoggin(result[0])
                // this.$router.push({ name: 'Gestión de Usuarios' })
                var tmp = this.getRedirectTo;
                if (tmp != null) {
                    this.setLoginDg(false);
                    location.href = tmp;
                }
                this.setLoginDg(false);

            } else {
                this.textSnackBar = 'Usuario o contraseña incorrecto'
                this.snackbar = true
            }

            this.overlay = false
        },
        async authCreate() {
            if (this.nombre != '' && this.email != '' && this.pais != '' && this.password != '') {
                this.overlay = true;
                var consulta = await fetch(this.isProd ? this.urlProd + 'api/users-site/create' : this.urlTest + 'api/users-site/create', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: this.nombre,
                        email: this.email,
                        password: this.password,
                        pais: this.pais,
                        id_site: this.$route.params.id_site,
                        url: location.href
                    })
                }).then((res) => res.json());
                if (consulta.success) {
                    this.nombre = '';
                    this.email = '';
                    this.pais = '';
                    this.password = '';
                    alert(consulta.msg)
                    this.dialog3 = false;
                } else {
                    alert(consulta.msg)
                }
                this.overlay = false;
            }


            // var consulta = await fetch(this.isProd ? this.urlProd + 'api/resetPassword' : this.urlTest + 'api/resetPassword', {
            //         method: 'POST',
            //         headers: {
            //             'Accept': 'application/json',
            //             'Content-Type': 'application/json'
            //         },
            //         body: JSON.stringify({ token: urlParams.get('token'), password: this.password, id_site: this.$route.params.id_site })
            //     }).then((res) => res.json());
        },
        async authReset() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            if (this.password != '') {
                if (this.password != this.password1) {
                    this.errorPsw = true;
                } else {
                    this.errorPsw = false;
                    this.overlay = true;
                    var consulta = await fetch(this.isProd ? this.urlProd + 'api/resetPassword' : this.urlTest + 'api/resetPassword', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ token: urlParams.get('token'), password: this.password, id_site: this.$route.params.id_site })
                    }).then((res) => res.json());
                    if (consulta.success) {
                        this.dialog2 = false;
                        this.password = '';
                        this.password1 = '';
                        alert(consulta.msg)
                        location.href = location.href.split('?')[0];
                    }
                }
            }
        },
        async authRecover() {
            if (this.email != '') {
                this.overlay = true;
                var consulta = await fetch(this.isProd ? this.urlProd + 'api/users-site/recoverPassword' : this.urlTest + 'api/users-site/recoverPassword', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ email: this.email, url: location.href, id_business: this.$route.params.id_business })
                }).then((res) => res.json());
                if (consulta.success) {
                    this.dialog4 = false;
                    alert(consulta.msg)
                }
                this.overlay = false;
            }
        },
        async logout() {
            var self = this;
            await fetch(this.isProd ? this.urlProd + 'api/logout' : this.urlTest + 'api/logout', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((res) => res.json()).then(() => {
                self.isLogout();
            })
        },
        setLoginDg(val) {
            this.setLoginDialog(val)
        },
        ...mapMutations([
            'isLogged',
            'isLogout',
            'saveDataLoggin',
            'setLoginDialog',
            'setRedirectTo',
            'setSnippetDialog',
            'setSnippetDialogURL'
        ])
    },
    computed: {
        languagesFiltered() {
            return this.languages.filter(item => item.status)
        },
        loginDialog() {
            return this.getLoginDialog;
        },
        ...mapState([
            'urlProd',
            'urlTest',
            'isProd',
            'userInit',

        ]),
        ...mapGetters([
            'getLoginDialog',
            'getRedirectTo',
            'getSnippetDialog',
            'getSnippetDialogURL'
        ])
    },
    watch: {
        getLoginDialog: function (newDialog) {
            this.dialog = newDialog;
        },
        dialog: function (newDialog) {
            this.setLoginDialog(newDialog);
            if (newDialog == false) {
                this.setRedirectTo(null);
            }
        },
        getSnippetDialog: function (newDialog) {
            this.snippetDialog = newDialog;
            this.snippetDialogURL = this.getSnippetDialogURL;
        },
        snippetDialog: function (newDialog) {
            this.setSnippetDialog(newDialog);
            if (newDialog == false) {
                this.setSnippetDialogURL(null);
            }
        }

    },
    created: async function () {
        // if (this.isProd) {
        this.idUser = this.userInit.id
        // }
        //  else {
        //     this.idUser = 'a16505ba-f7f2-415a-8f80-b8e9bd36144b'
        // }
        if (this.$route.fullPath.includes('signin') || this.$route.fullPath.includes('signup')) {
            this.defaultPage = this.$route.fullPath.split('/')[this.$route.fullPath.split('/').length - 1];
            console.log(this.defaultPage);
        }
        // this.idUser = this.userInit.id
        this.indexPage = this.$route.params.dummy
        if (localStorage.getItem('lang')) {
            this.langSelected = localStorage.getItem('lang')
        }
        if (this.$route.params.lang) {
            this.langSelected = this.$route.params.lang
        }
        // id_site/:id_business
        this.overlay = true
        let result = await fetch(this.isProd ? this.urlProd + 'api/sites/' + this.$route.params.id_site + '/' + this.$route.params.id_business : this.urlTest + 'api/sites/' + this.$route.params.id_site + '/' + this.$route.params.id_business).then((res) => res.json())
        this.overlay = false
        if (result.success && result.code == 200) {
            // this.listPages = result.data.reverse();
            var tmp = result.data.sort((a, b) => a.order_page - b.order_page);
            // Only intoheader
            tmp.forEach(p => {
                if (p.profile) {
                    this.profilePath = '/pg/' + p.id + '/' + p.id_business + '/' + p.url;
                }
                // if (p.intoheader == true) {
                this.listPages.push(p);
                // }
            })
            this.loadPage()
        }
    },
    mounted: async function () {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.get('reset') == 'true' && urlParams.get('token') != null) {
            var token = await fetch(this.isProd ? this.urlProd + 'api/users-site/checkToken' : this.urlTest + 'api/users-site/checkToken', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token: urlParams.get('token') })
            }).then((res) => res.json())

            if (token.data.length == 0) {
                this.validToken = false;
            } else {
                // Check time
                const date = new Date();
                const diff = date.getTime() - new Date(token.data[0]['timestamp']['value']);
                const seconds = diff / 1000;
                if (seconds < 86400) { // 24 Horas
                    this.token = urlParams.get('token')
                } else {
                    this.validToken = false;
                }
            }
            this.dialog2 = true;
        }
    },
    updated: function () {
        // Menu responsivo
        // Manual
        if (this.menuListening == false) {
            console.log('install');
            var el = document.getElementById('appbar1');
            if (el) {
                if (el.scrollWidth > el.clientWidth) {
                    this.menuOverflow = el.scrollWidth;
                    this.menuResponsive = true;
                } else {
                    this.menuResponsive = false;
                }
            }

            // Auto
            window.addEventListener('resize', () => {
                if (this.menuOverflow == null) {
                    var el = document.getElementById('appbar1');
                    if (el.scrollWidth > el.clientWidth) {
                        this.menuResponsive = true;
                        this.menuOverflow = el.scrollWidth;
                    } else {
                        this.menuResponsive = false;
                    }
                }
                if (window.innerWidth > this.menuOverflow) {
                    this.menuResponsive = false;
                } else {
                    this.menuResponsive = true;
                }
            })
            this.menuListening = true;
        }

    },
    metaInfo() {
        console.log(this.favicon);
        return {
            title: `${this.nameBusiness} - ${this.getTitle(this.idPage)}`,
            link: [
                { rel: 'icon', type: 'image/png', href: this.favicon }
            ]
            //   { name: 'description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline },
            //   { property: 'og:title', content: this.userData.name + ' - Epiloge' },
            //   { property: 'og:site_name', content: 'Epiloge' },
            //   { property: 'og:description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline },
            //   { property: 'og:type', content: 'profile' },
            //   { property: 'og:url', content: 'https://epiloge.com/@' + this.userData.username },
            //   { property: 'og:image', content: this.aws_url + '/users/' + this.userData.profileurl + '-main.jpg' }
            // ]
        }
    },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700& display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700& display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;600;700&display=swap');

.container-element {
    height: calc(100% - 48px);
    background-color: white;
    padding: 0;
    overflow-y: auto;
}

.container-element-snap {
    cursor: initial;
}
</style>