<template>
    <v-footer dark padless>
        <v-card flat tile width="100%" class="primary white--text text-center">
            <v-divider></v-divider>
            <v-card-text class="white--text">
                {{ new Date().getFullYear() }} — <strong>GLocation</strong>
            </v-card-text>
        </v-card>
    </v-footer>
</template>
<script>
import vuetify from '../plugins/vuetify'

export default {
    vuetify
}
</script>