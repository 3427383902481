import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    urlProd: '/',
    urlTest: 'http://localhost:8080/',
    isProd: true,
    isLogin: false,
    userInit: {},
    elementSelect: null,
    listOfElementsParent: null,
    listOfElements2Add: null,
    isContainer: false,
    attrElement: null,
    changeElement: false,
    parent: null,
    loginDialog: false,
    redirectTo: null,
    snippetDialog: false,
    snippetDialogURL: null
  },
  mutations: {
    isLogged(state) {
      state.isLogin = true
    },
    isLogout(state) {
      state.isLogin = false
      state.userInit = {}
    },
    saveDataLoggin(state, data) {
      state.userInit = data
    },

    setSelectElement(state, data) {
      state.elementSelect = data
      state.changeElement = false
    },
    removeSelectElement(state) {
      state.elementSelect = null
      state.changeElement = false
    },

    setAttrElement(state, data) {
      state.attrElement = data
    },
    removeAttrElement(state) {
      state.attrElement = null
    },
    // updateAttrElement(state, data) {
    //   let obj = data[0]
    //   let name = data[1]
    //   state.attrElement[name] = obj[name]
    // },
    updateAttrElement(state, data) {
      let obj = data[0]
      let name = data[1]
      if (state.elementSelect != null) {
        for (let i = 0; i < state.listOfElementsParent.length; i++) {
          if (obj['id'] == state.listOfElementsParent[i]['id']) {
            state.listOfElementsParent[i]['attrs'][name] = obj[name]
          }
        }
      }
    },

    setIsContainer(state, data) {
      state.isContainer = data
    },

    setParent(state, data) {
      state.parent = data;
    },

    addListParent(state, data) {
      state.listOfElementsParent = data
    },
    removeListParent(state) {
      state.listOfElementsParent = null
    },
    addElements2ListParent(state, data) {
      state.listOfElementsParent.push(data)
    },
    removeElements2ListParent(state) {
      if (state.elementSelect != null) {
        let indexElement = null
        for (let i = 0; i < state.listOfElementsParent.length; i++) {
          if (state.listOfElementsParent[i].id == state.elementSelect.id) {
            indexElement = i
          }
        }
        state.listOfElementsParent.splice(indexElement, 1);
      }
    },


    addList2Add(state, data) {
      state.listOfElements2Add = data
    },
    removeList2Add(state) {
      state.listOfElements2Add = null
    },
    addElements2List2Add(state, data) {
      state.listOfElements2Add.push(data)
    },
    removeElements2List2Add(state) {
      if (state.elementSelect != null) {
        let indexElement = null
        for (let i = 0; i < state.listOfElements2Add.length; i++) {
          if (state.listOfElements2Add[i].id == state.elementSelect.id) {
            indexElement = i
          }
        }
        state.listOfElements2Add.splice(indexElement, 1);
      }
    },
    setLoginDialog(state, data) {
      state.loginDialog = data;
    },
    setRedirectTo(state, data) {
      state.redirectTo = data;
    },
    setSnippetDialog(state, data) {
      state.snippetDialog = data;
    },
    setSnippetDialogURL(state, data){
      state.snippetDialogURL = data;
    }
  },
  getters: {
    getSelectElement(state) {
      return state.elementSelect
    },
    getListElement(state) {
      return state.listOfElementsParent
    },
    getIsContainer(state) {
      return state.isContainer
    },
    getListElement2Add(state) {
      return state.listOfElements2Add
    },
    getAttrElement(state) {
      return state.attrElement
    },
    getAnyChangeElement(state) {
      return state.changeElement
    },
    getParent(state) {
      return state.parent;
    },
    getLoginDialog(state) {
      return state.loginDialog;
    },
    getRedirectTo(state){
      return state.redirectTo;
    },
    getSnippetDialog(state) {
      return state.snippetDialog;
    },
    getSnippetDialogURL(state){
      return state.snippetDialogURL;
    }
  },
  actions: {},
  modules: {}
})