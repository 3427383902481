<template>
    <v-main>
        <v-container fluid class="fill-height pa-0">
            <v-col cols="2" class="fill-height pa-0" style="border-right: 1px solid gray;">
                <v-row class="fill-height pa-0">
                    <v-col cols="12" class="fill-height">
                        <div class="mb-0 pt-2">
                            <h3 class="text-center" @click="debug">Insertar Contenedor</h3>
                        </div>
                        <v-list>
                            <v-list-item>
                                <v-btn class="ma-2" outlined small color="indigo" @click="addElement('seccion')">
                                    <v-icon left>
                                        mdi-newspaper
                                    </v-icon>
                                    Sección
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn class="ma-2" outlined small color="indigo" @click="addElement('embed')">
                                    <v-icon left>
                                        mdi-newspaper
                                    </v-icon>
                                    Embed
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn class="ma-2" outlined small color="indigo" @click="addElement('iframe')">
                                    <v-icon left>
                                        mdi-newspaper
                                    </v-icon>
                                    Iframe
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn class="ma-2" outlined small color="indigo" @click="addElement('newsletter')">
                                    <v-icon left>
                                        mdi-newspaper
                                    </v-icon>
                                    Newsletter
                                </v-btn>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <div class="mb-0 pt-2">
                            <h3 class="text-center">Insertar Elementos</h3>
                        </div>
                        <v-list>
                            <v-list-item>
                                <v-btn class="ma-2" outlined small color="indigo" @click="addElement('texto')">
                                    <v-icon left>
                                        mdi-newspaper
                                    </v-icon>
                                    Parrafo
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn class="ma-2" outlined small color="indigo" @click="addElement('imagen')">
                                    <v-icon left>
                                        mdi-newspaper
                                    </v-icon>
                                    Imagen
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn class="ma-2" outlined small color="indigo" @click="addElement('logo')">
                                    <v-icon left>
                                        mdi-newspaper
                                    </v-icon>
                                    Logo
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn class="ma-2" outlined small color="indigo" @click="addElement('input')">
                                    <v-icon left>
                                        mdi-newspaper
                                    </v-icon>
                                    Input
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn class="ma-2" outlined small color="indigo" @click="addElement('button')">
                                    <v-icon left>
                                        mdi-newspaper
                                    </v-icon>
                                    Botón
                                </v-btn>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="8" class="fill-height bg-gray">
                <v-container class="fill-height">
                    <v-toolbar height="30" color="primary" dark elevation="1"><v-spacer></v-spacer><span
                            color="secondary">{{ name }}</span><v-spacer></v-spacer></v-toolbar>
                    <v-container class="container-elements" style="border: 1px solid black;">
                        <div class="text-center ma-3" v-if="listElements.length == 0">
                            <h3>Cree su Landing Page</h3>
                            <p>Agregue los elementos que desee de la parte izquierda de su pantalla y creela en el
                                menor tiempo posible</p>
                        </div>
                        <component v-for="field in listElements" :key="field.id" v-bind:is="field.type" v-bind:id="field.id"
                            v-bind:attrs="field.attrs" v-bind:listElements="field.listElements"
                            v-bind:listParent="listElements" v-bind:edit="true"></component>
                    </v-container>
                </v-container>
            </v-col>
            <v-col cols="2" class="fill-height" style="border-left: 1px solid gray; overflow-y: auto;">
                <div class="mb-0 pt-2">
                    <h3 class="text-center">Opciones página</h3>
                </div>
                <div class="text-center">
                    <v-btn class="ma-2" outlined small color="primary" @click="saveTemplate()">
                        <v-icon left>
                            mdi-content-save-check
                        </v-icon>
                        Guardar
                    </v-btn>
                    <v-btn class="ma-2" outlined small color="red" :href="'/view/' + idSite">
                        <v-icon left>
                            mdi-close
                        </v-icon>
                        Cerrar
                    </v-btn>
                </div>
                <div class="mb-0 pt-2">
                    <h3 class="text-center">Opciones Elemento</h3>
                </div>
                <div class="text-center">
                    <v-btn class="ma-2" outlined small color="gray" @click="deleteElement()">
                        <v-icon left>
                            mdi-delete
                        </v-icon>
                        Borrar
                    </v-btn>
                </div>
                <v-divider></v-divider>
                <!-- <v-text-field label="Tag de Sección" v-model="tagName" @keyup="changeTag"></v-text-field> -->
                <!-- Embed & Iframe-->
                <div v-if="urlTemp.length > 0">
                    <v-text-field v-for="(elem, index) in listLanguages" :key="index" :label="'URL Idioma ' + (index + 1)"
                        v-model="urlTemp[index]" @keyup="changeURL(index)"></v-text-field>
                </div>
                <!-- Iframe -->
                <div v-if="checkCtrl('urlParamUser')">
                    <v-text-field label="Parametro ID de usuario" v-model="urlParamUser" @keyup="changeUrlParamUser"></v-text-field>
                </div>
                <div v-if="urlLoggedTemp.length > 0">
                    <v-text-field v-for="(elem, index) in listLanguages" :key="index" :label="'URL autentificada Idioma ' + (index + 1)"
                        v-model="urlLoggedTemp[index]" @keyup="changeURL2(index)"></v-text-field>
                </div>
                <!-- All -->
                <v-text-field v-if="widthTemp != ''" label="Ancho (%)" v-model="widthTemp"
                    @keyup="changeWidth"></v-text-field>
                <v-text-field v-if="heightTemp != '' || sizeTemp == 'custom'" label="Alto (px)" v-model="heightTemp"
                    @keyup="changeHeight"></v-text-field>
                <v-text-field v-if="xTemp != ''" label="Posición en X (%)" v-model="xTemp"
                    @keyup="changePosX"></v-text-field>
                <v-text-field v-if="yTemp != ''" label="Posición en Y (px)" v-model="yTemp"
                    @keyup="changePosY"></v-text-field>

                <!-- Text -->
                <div v-if="textTemp.length > 0">
                    <v-text-field v-for="(elem, index) in listLanguages" :key="index" :label="'Texto Idioma ' + (index + 1)"
                        v-model="textTemp[index]" @keyup="changeText(index)"></v-text-field>
                </div>

                <v-select v-if="sizeFont != ''" :items="sizeFontList" v-model="sizeFont" label="Tamaño de Letra"
                    @change="changeSizeFont"></v-select>
                <v-select v-if="typeFont != ''" :items="typeFontList" item-text="text" item-value="value" v-model="typeFont"
                    label="Tipo de Letra" @change="changeTypeFont"></v-select>
                <div v-if="checkCtrl('backgroundType')">
                    <v-select :items="typeBackground" v-model="backgroundType" label="Tipo de fondo"
                        @change="changeBackgroundType"></v-select>
                    <div v-if="backgroundType == 'imagen'">
                        <v-file-input truncate-length="15" accept="image/*" label="Imagen de Fondo"
                            v-model="backgroundImageFile" @change="createBase64Image"></v-file-input>

                        <v-btn class="ma-2" outlined small color="gray" @click="saveElement()">
                            <v-icon left>
                                mdi-sync
                            </v-icon>
                            Guardar Imagen
                        </v-btn>
                    </div>
                    <div v-if="backgroundType == 'color'">
                        <p class="mb-1">Color de Fondo</p>
                        <div class="shrink" style="min-width: 220px;">
                            <v-text-field v-model="backgroundColor" hide-details class="ma-0 pa-0" solo>
                                <template v-slot:append>
                                    <v-menu v-model="menuBackgroundColor" top nudge-bottom="105" nudge-left="16"
                                        :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div :style="swatchStyleBackground(backgroundColor, menuBackgroundColor)"
                                                v-on="on" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="backgroundColor" mode="hexa" />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </div>
                    </div>
                </div>

                <br>
                <div v-if="textColor != ''">
                    <p class="mb-1">Color de Texto</p>
                    <div class="shrink" style="min-width: 220px;">
                        <v-text-field v-model="textColor" hide-details class="ma-0 pa-0" solo>
                            <template v-slot:append>
                                <v-menu v-model="menuTextColor" top nudge-bottom="105" nudge-left="16"
                                    :close-on-content-click="false">
                                    <template v-slot:activator="{ on }">
                                        <div :style="swatchStyleText(textColor, menuTextColor)" v-on="on" />
                                    </template>
                                    <v-card>
                                        <v-card-text class="pa-0">
                                            <v-color-picker v-model="textColor" mode="hexa" />
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </template>
                        </v-text-field>
                    </div>
                </div>
                <div v-if="justifyContent != ''" class="d-flex align-center flex-column bg-grey-lighten-4 pa-6">
                    <p>Alineación</p>
                    <v-btn-toggle v-model="justifyContent" divided>

                        <v-btn value="left">
                            <v-icon>mdi-format-align-left</v-icon>
                        </v-btn>

                        <v-btn value="center">
                            <v-icon>mdi-format-align-center</v-icon>
                        </v-btn>

                        <v-btn value="right">
                            <v-icon>mdi-format-align-right</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </div>
                <div v-if="alignItems != ''" class="d-flex align-center flex-column bg-grey-lighten-4 pa-6">
                    <p>Alineación Vertical</p>
                    <v-btn-toggle v-model="alignItems" divided>
                        <v-btn value="start">
                            <v-icon>mdi-format-vertical-align-top</v-icon>
                        </v-btn>

                        <v-btn value="center">
                            <v-icon>mdi-format-vertical-align-center</v-icon>
                        </v-btn>

                        <v-btn value="end">
                            <v-icon>mdi-format-vertical-align-bottom</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </div>

                <v-select v-if="sizeTemp != ''" :items="heightSize" item-text="text" item-value="value" v-model="sizeTemp"
                    label="Tamaño de la Sección" @change="changeSizeCover"></v-select>

                <div v-if="urlImgTemp.length > 0">
                    <v-text-field v-for="(elem, index) in listLanguages" :key="index"
                        :label="'URL Imagen Idioma ' + (index + 1)" v-model="urlImgTemp[index]"
                        @keyup="changeURLImg(index)"></v-text-field>
                </div>
            </v-col>
        </v-container>
        <Overlay :overlay="overlay"></Overlay>
    </v-main>
</template>

<script>
// Todos los Componentes que vayan dentro de una seccion deben registrarse en el componente Seccion
import vuetify from '../plugins/vuetify'
import Overlay from '../components/Overlay.vue'
import SectionComponent from '../components/SectionComponent.vue'
import EmbedComponent from '../components/EmbedComponent.vue'
import IframeComponent from '../components/IframeComponent.vue'
import TextComponent from '../components/TextComponent.vue'
import ImgComponent from '../components/ImgComponent.vue'
import ButtonComponent from '../components/ButtonComponent.vue'
import InputComponent from '../components/InputComponent.vue'
import NewsLetterComponent from '../components/NewsLetterComponent.vue'
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
    name: 'EditPages',
    vuetify,
    data: () => ({
        listElements: [],
        listLanguages: [],
        listDummyText: [],
        listDummyImgs: [],
        name: null,
        idPage: null,
        idSite: null,
        overlay: false,
        show: true,
        settingsSite: {},
        heightSize: [
            {
                text: "Pequeño",
                value: 'small'
            },
            {
                text: "Mediano",
                value: 'medium'
            },
            {
                text: "Grande",
                value: 'large'
            },
            {
                text: "Máximo",
                value: 'max'
            },
            {
                text: 'Personalizado',
                value: 'custom'
            }
        ],
        sizeFontList: ['12px', '14px', '16px', '18px', '24px', '30px', '34px', '48px', '72px'],
        typeFontList: [
            {
                text: "American Typewriter",
                value: 'American Typewriter, serif'
            },
            {
                text: "Andalé Mono",
                value: 'Andalé Mono, monospace'
            },
            {
                text: "Arial",
                value: 'Arial, sans-serif'
            },
            {
                text: "Arial Black",
                value: 'Arial Black, sans-serif'
            },
            {
                text: "Bradley Hand",
                value: 'Bradley Hand, cursive'
            },
            {
                text: "Courier",
                value: 'Courier, monospace'
            },
            {
                text: "Comfortaa",
                value: 'Comfortaa'
            },
            {
                text: "Didot",
                value: 'Didot, serif'
            },
            {
                text: "DM Sans",
                value: 'DM Sans'
            },
            {
                text: "Josefin Sans",
                value: 'Josefin Sans'
            },
            {
                text: "Georgia",
                value: 'Georgia, serif'
            },
            {
                text: "Impact",
                value: 'Impact, sans-serif, serif'
            },
            {
                text: "Lucida Console",
                value: 'Lucida Console, monospace'
            },
            {
                text: "Luminari",
                value: 'Luminari, fantasy'
            },
            {
                text: "Poppins",
                value: 'Poppins'
            },
            {
                text: "Tahoma",
                value: 'Tahoma, sans-serif'
            },
            {
                text: "Times New Roman",
                value: 'Times New Roman, serif'
            },
            {
                text: "Trebuchet MS",
                value: 'Trebuchet MS, sans-serif'
            },
            {
                text: "Verdana",
                value: 'Verdana, sans-serif'
            },
            {
                text: "Work Sans",
                value: 'Work Sans'
            }],
        typeBackground: [
            {
                text: 'Color',
                value: 'color'
            },
            {
                text: 'Imagen',
                value: 'imagen'
            }
        ],
        // *******
        tagName: '',
        urlTemp: [],
        urlLoggedTemp: [],
        urlImgTemp: [],
        urlParamUser: '',

        textTemp: [],
        sizeFont: '',
        typeFont: '',
        menuBackgroundColor: false,
        backgroundColor: '#076932FF',
        menuTextColor: false,
        textColor: '#076932FF',
        backgroundType: 'color',
        backgroundImageFile: null,
        backgroundImage: '',
        justifyContent: 'left',
        alignItems: 'start',


        heightTemp: '',
        widthTemp: '',
        xTemp: '',
        yTemp: '',


        menuColor: false,
        elementColor: '#076932FF',
        sizeTemp: 'small',
        controls: {
            "SectionComponent": ['backgroundType'],
            "TextComponent": ['backgroundType'],
            "ButtonComponent": ['backgroundType'],
            "InputComponent": ['backgroundType'],
            "IframeComponent": ['urlParamUser']
        }
    }),
    components: {
        Overlay,
        TextComponent,
        ImgComponent,
        EmbedComponent,
        IframeComponent,
        SectionComponent,
        InputComponent,
        ButtonComponent,
        NewsLetterComponent
    },
    methods: {
        addElement(id) {
            console.log(id, this.getSelectElement)
            let elemTemp = this.getSelectElement
            if (elemTemp == null) {
                if (id == 'seccion') {
                    this.listElements.push({
                        type: "SectionComponent",
                        id: new Date().getTime(),
                        attrs: {
                            styleColorBackground: this.randColor(),
                            styleImageBackground: null,
                            styleBackgroundType: 'color',
                            sizeCover: 'medium',
                            className: '',
                            axisX: 0,
                            axisY: 0,
                            heightElem: '300px',
                            widthElem: '100%'
                        },
                        listElements: []
                    })
                } else if (id == 'embed') {
                    this.listElements.push({
                        type: "EmbedComponent",
                        id: new Date().getTime(),
                        attrs: {
                            isPublish: false,
                            src: JSON.parse(JSON.stringify(this.listDummyText)),
                            className: '',
                            axisX: 0,
                            axisY: 0,
                            heightElem: '1000px',
                            widthElem: '100%'
                        }
                    })
                } else if (id == 'iframe') {
                    this.listElements.push({
                        type: "IframeComponent",
                        id: new Date().getTime(),
                        attrs: {
                            isPublish: false,
                            src: JSON.parse(JSON.stringify(this.listDummyText)),
                            src2: JSON.parse(JSON.stringify(this.listDummyText)),
                            className: '',
                            axisX: 0,
                            axisY: 0,
                            heightElem: '1000px',
                            widthElem: '100%',
                            urlParamUser: ''
                        }
                    })
                } else if (id == 'newsletter') {
                    // this.listElements.push({
                    //     type: "NewsLetterComponent",
                    //     id: new Date().getTime(),
                    //     attrs: {
                    //         isPublish: false,
                    //         lang: this.listLanguages,
                    //         styleColorBackground: this.randColor(),
                    //         styleImageBackground: null,
                    //         styleColorTextHelp: 'red',
                    //         sizeCover: 'medium',
                    //         className: '',
                    //         axisX: 0,
                    //         axisY: 0,
                    //         heightElem: '350px',
                    //         widthElem: '100%',
                    //         children: [
                    //             {
                    //                 id: new Date().getTime(),
                    //                 attrs: {
                    //                     text: JSON.parse(JSON.stringify(this.listDummyText)),
                    //                     styleTypeFont: 'Arial, sans-serif',
                    //                     styleWeightFont: '400',
                    //                     styleSizeFont: '16px',
                    //                     styleColorBackground: '#FFFFFF00',
                    //                     styleImageBackground: '',
                    //                     styleColorText: '#000000FF',
                    //                     justifyContent: 'left',
                    //                     alignItems: 'center',
                    //                     className: "strong",
                    //                     axisX: 0,
                    //                     axisY: 0,
                    //                     heightElem: 50,
                    //                     widthElem: '25%'
                    //                 }
                    //             },
                    //             {
                    //                 id: new Date().getTime(),
                    //                 attrs: {
                    //                     text: JSON.parse(JSON.stringify(this.listDummyText)),
                    //                     styleTypeFont: 'Arial, sans-serif',
                    //                     styleWeightFont: '400',
                    //                     styleSizeFont: '16px',
                    //                     styleColorBackground: '#FFFFFF00',
                    //                     styleImageBackground: '',
                    //                     styleColorText: '#000000FF',
                    //                     justifyContent: 'left',
                    //                     alignItems: 'center',
                    //                     className: "strong",
                    //                     axisX: 100,
                    //                     axisY: 70,
                    //                     heightElem: 50,
                    //                     widthElem: '25%'
                    //                 }
                    //             },
                    //             {
                    //                 id: new Date().getTime(),
                    //                 attrs: {
                    //                     text: this.listDummyText,
                    //                     styleTypeFont: 'Arial, sans-serif',
                    //                     styleWeightFont: '400',
                    //                     styleSizeFont: '16px',
                    //                     styleColorBackground: '#FFFFFF00',
                    //                     styleColorText: '#000000FF',
                    //                     justifyContent: 'left',
                    //                     typeInput: "text",
                    //                     className: 'form-control form-control-sm height-100',
                    //                     axisX: 100,
                    //                     axisY: 180,
                    //                     heightElem: 200,
                    //                     widthElem: '25%'
                    //                 }
                    //             },
                    //             {

                    //                 id: new Date().getTime(),
                    //                 attrs: {
                    //                     text: this.listDummyText,
                    //                     styleTypeFont: 'Arial, sans-serif',
                    //                     styleWeightFont: '400',
                    //                     styleSizeFont: '16px',
                    //                     styleColorBackground: '#FFFFFF00',
                    //                     styleColorText: '#000000FF',
                    //                     justifyContent: 'left',
                    //                     typeInput: "text",
                    //                     className: 'form-control form-control-sm height-100',
                    //                     axisX: 100,
                    //                     axisY: 240,
                    //                     heightElem: 200,
                    //                     widthElem: '25%'
                    //                 }
                    //             },
                    //             {
                    //                 id: new Date().getTime(),
                    //                 attrs: {
                    //                     text: this.listDummyText,
                    //                     justifyContent: null,
                    //                     styleColorText: 'red',
                    //                     styleColorBackground: 'blue',
                    //                     className: 'btn btn-primary',
                    //                     isPublish: false,
                    //                     axisX: 100,
                    //                     axisY: 300,
                    //                     heightElem: 150,
                    //                     widthElem: '25%'
                    //                 }
                    //             }
                    //         ]
                    //     }
                    // })

                    this.listElements.push({
                        "type": "SectionComponent",
                        "id": 1678314509402,
                        "attrs": {
                            "styleColorBackground": "#A5CDFF6B",
                            "styleImageBackground": null,
                            "styleBackgroundType": 'color',
                            "sizeCover": "small",
                            "className": "",
                            "axisX": 0,
                            "axisY": 0,
                            "heightElem": "300px",
                            "widthElem": "100%",
                            "justifyContent": "",
                            "alignItems": ""
                        },
                        "listElements": [
                            {
                                "type": "TextComponent",
                                "id": 1,
                                "attrs": {
                                    "text": [
                                        "Título"
                                    ],
                                    "styleTypeFont": "Arial, sans-serif",
                                    "styleWeightFont": "400",
                                    "styleSizeFont": "16px",
                                    "styleColorBackground": "#FFFFFF00",
                                    "styleImageBackground": "",
                                    "styleBackgroundType": 'color',
                                    "styleColorText": "#000000FF",
                                    "justifyContent": "left",
                                    "alignItems": "center",
                                    "className": "strong",
                                    "axisX": 26.9375,
                                    "axisY": 41.20000457763672,
                                    "heightElem": 50,
                                    "widthElem": "14.673916279700755%"
                                }
                            },
                            {
                                "type": "TextComponent",
                                "id": 2,
                                "attrs": {
                                    "text": [
                                        "Descripción"
                                    ],
                                    "styleTypeFont": "Arial, sans-serif",
                                    "styleWeightFont": "400",
                                    "styleSizeFont": "16px",
                                    "styleColorBackground": "#FFFFFF00",
                                    "styleImageBackground": "",
                                    "styleBackgroundType": 'color',
                                    "styleColorText": "#000000FF",
                                    "justifyContent": "left",
                                    "alignItems": "center",
                                    "className": "strong",
                                    "axisX": 26.9375,
                                    "axisY": 161.1999969482422,
                                    "heightElem": 50,
                                    "widthElem": "10.608431188071647%"
                                }
                            },
                            {
                                "type": "InputComponent",
                                "id": 'newsletter_name',
                                "attrs": {
                                    "text": [
                                        "Nombre"
                                    ],
                                    "styleTypeFont": "Arial, sans-serif",
                                    "styleWeightFont": "400",
                                    "styleSizeFont": "16px",
                                    "styleColorBackground": "#FFFFFF00",
                                    "styleColorText": "#000000FF",
                                    "justifyContent": "",
                                    "typeInput": "text",
                                    "className": "form-control form-control-sm height-100",
                                    "axisX": 115.33749389648438,
                                    "axisY": 49.59999084472656,
                                    "heightElem": "50",
                                    "widthElem": "25%",
                                    "alignItems": ""
                                }
                            },
                            {
                                "type": "InputComponent",
                                "id": 'newsletter_email',
                                "attrs": {
                                    "text": [
                                        "Email"
                                    ],
                                    "styleTypeFont": "Arial, sans-serif",
                                    "styleWeightFont": "400",
                                    "styleSizeFont": "16px",
                                    "styleColorBackground": "#FFFFFF00",
                                    "styleColorText": "#000000FF",
                                    "justifyContent": "",
                                    "typeInput": "text",
                                    "className": "form-control form-control-sm height-100",
                                    "axisX": 386.9375,
                                    "axisY": 51.19999694824219,
                                    "heightElem": 50,
                                    "widthElem": "27.896341463414632%",
                                    "alignItems": ""
                                }
                            },
                            {
                                "type": "InputComponent",
                                "id": 'newsletter_country',
                                "attrs": {
                                    "text": [
                                        "País"
                                    ],
                                    "styleTypeFont": "Arial, sans-serif",
                                    "styleWeightFont": "400",
                                    "styleSizeFont": "16px",
                                    "styleColorBackground": "#FFFFFF00",
                                    "styleColorText": "#000000FF",
                                    "justifyContent": "left",
                                    "typeInput": "text",
                                    "className": "form-control form-control-sm height-100",
                                    "axisX": 676.9375,
                                    "axisY": 51.19999694824219,
                                    "heightElem": 50,
                                    "widthElem": "25.266438197988467%"
                                }
                            },
                            {
                                "type": "ButtonComponent",
                                "id": 'newsletter_submit',
                                "attrs": {
                                    "text": [
                                        "Suscribirme"
                                    ],
                                    "justifyContent": "",
                                    "styleColorText": "#FFFFFFFF",
                                    "styleColorBackground": "#004CFFED",
                                    "className": "btn btn-primary",
                                    "isPublish": false,
                                    "axisX": 366.9375,
                                    "axisY": 161.20001220703125,
                                    "heightElem": "16.666666666666668%",
                                    "widthElem": "25.011928716115456%",
                                    "alignItems": ""
                                }
                            },
                            {
                                "type": "TextComponent",
                                "id": 7,
                                "attrs": {
                                    "text": [
                                        "Política de privacidad"
                                    ],
                                    "styleTypeFont": "Arial, sans-serif",
                                    "styleWeightFont": "400",
                                    "styleSizeFont": "16px",
                                    "styleColorBackground": "#FFFFFF00",
                                    "styleImageBackground": "",
                                    "styleBackgroundType": 'color',
                                    "styleColorText": "#000000FF",
                                    "justifyContent": "center",
                                    "alignItems": "center",
                                    "className": "strong",
                                    "axisX": 366.9375,
                                    "axisY": 221.20001220703125,
                                    "heightElem": 50,
                                    "widthElem": "25%"
                                }
                            }
                        ]
                    })
                }
            } else {
                if (id == 'texto') {
                    this.addElements2List2Add({
                        type: "TextComponent",
                        id: new Date().getTime(),
                        attrs: {
                            text: JSON.parse(JSON.stringify(this.listDummyText)),
                            styleTypeFont: 'Arial, sans-serif',
                            styleWeightFont: '400',
                            styleSizeFont: '16px',
                            styleColorBackground: '#FFFFFF00',
                            styleImageBackground: '',
                            "styleBackgroundType": 'color',
                            styleColorText: '#000000FF',
                            justifyContent: 'left',
                            alignItems: 'center',
                            className: "strong",
                            axisX: 0,
                            axisY: 0,
                            heightElem: 100,
                            widthElem: '25%'
                        }
                    })
                } else if (id == 'imagen') {
                    this.addElements2List2Add({
                        type: "ImgComponent",
                        id: new Date().getTime(),
                        attrs: {
                            src: this.listDummyImgs,
                            className: '',
                            axisX: 100,
                            axisY: 200,
                            heightElem: 200,
                            widthElem: '25%'
                        }
                    })
                } else if (id == 'logo') {
                    this.addElements2List2Add({
                        type: "ImgComponent",
                        id: new Date().getTime(),
                        attrs: {
                            src: this.listDummyImgs,
                            logo: true,
                            className: '',
                            axisX: 100,
                            axisY: 200,
                            heightElem: 200,
                            widthElem: '25%'
                        }
                    })
                } else if (id == 'input') {
                    this.addElements2List2Add({
                        type: "InputComponent",
                        id: new Date().getTime(),
                        attrs: {
                            text: JSON.parse(JSON.stringify(this.listDummyText)),
                            styleTypeFont: 'Arial, sans-serif',
                            styleWeightFont: '400',
                            styleSizeFont: '16px',
                            styleColorBackground: '#FFFFFF00',
                            styleColorText: '#000000FF',
                            justifyContent: 'left',
                            typeInput: "text",
                            className: 'form-control form-control-sm height-100',
                            axisX: 100,
                            axisY: 200,
                            heightElem: 200,
                            widthElem: '25%'
                        }
                    })
                } else if (id == 'button') {
                    this.addElements2List2Add({
                        type: "ButtonComponent",
                        id: new Date().getTime(),
                        attrs: {
                            text: JSON.parse(JSON.stringify(this.listDummyText)),
                            justifyContent: 'center',
                            styleColorText: 'red',
                            styleColorBackground: 'blue',
                            className: 'btn btn-primary',
                            isPublish: false,
                            axisX: 100,
                            axisY: 200,
                            heightElem: 150,
                            widthElem: '25%'
                        }
                    })
                }
            }
        },
        async saveTemplate() {
            this.overlay = true
            let newStructure = []
            var i = 0;
            for await (let item of this.listElements) {
                // Save container images
                if (item.attrs.styleImageBackground != null && !item.attrs.styleImageBackground.includes('https')) {
                    item.attrs.styleImageBackground = await this.getImgUrl(item.attrs.styleImageBackground)
                }
                newStructure.push({
                    type: this.listElements[i].type,
                    id: this.listElements[i].id,
                    attrs: this.listElements[i].attrs,
                    listElements: this.listElements[i].listElements
                })
                i++;
            }

            console.log(newStructure)
            var data = {
                name: this.name,
                id_page: this.idPage,
                structure: JSON.stringify(newStructure)
            };
            console.log(data);

            let result = await fetch(this.isProd ? this.urlProd + 'api/pages/save' : this.urlTest + 'api/pages/save', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then((res) => res.json())
            console.log(result);
            this.overlay = false
        },
        async getImgUrl(data) {
            if (data.includes("https://")) {
                return data
            }
            this.overlay = true;
            let idImageNew = new Date().getTime()
            let nameImg = idImageNew + ".png"
            let blob = await fetch(data).then(res => res.blob())
            let file = new File([blob], nameImg, { type: blob.type })
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var formdata = new FormData();
            formdata.append("img", file, file.name);
            formdata.append("id_img", idImageNew);
            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };
            let urlUpload = this.isProd ? this.urlProd + 'api/upload-image' : this.urlTest + 'api/upload-image'
            let resultURL = await fetch(urlUpload, requestOptions).then(response => response.json())
            console.log(resultURL.message)
            this.overlay = false;
            return resultURL.message
        },
        convert2Editable(string) {
            let structure = JSON.parse(string)
            for (let i = 0; i < structure.length; i++) {
                structure[i].type = structure[i].name
            }
            return structure
        },
        randColor: () => {
            return "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(8, '0').toUpperCase();
        },
        changeTag() {
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                if (elemTemp.tagName) elemTemp.tagName = this.tagName
                this.updateAttrElement([{
                    id: elemTemp.id,
                    tagName: this.tagName
                }, 'tagName'])
            }
        },
        changeURL() {
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                this.updateAttrElement([{
                    id: elemTemp.id,
                    src: this.urlTemp
                }, 'src'])
            }
        },
        changeURL2() {
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                this.updateAttrElement([{
                    id: elemTemp.id,
                    src2: this.urlLoggedTemp
                }, 'src2'])
            }
        },
        changeURLImg() {
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                this.updateAttrElement([{
                    id: elemTemp.id,
                    src: this.urlImgTemp
                }, 'src'])
            }
        },
        //Text
        changeText() {
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                this.updateAttrElement([{
                    id: elemTemp.id,
                    text: this.textTemp
                }, 'text'])
            }
        },
        changeSizeFont() {
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                this.updateAttrElement([{
                    id: elemTemp.id,
                    styleSizeFont: this.sizeFont
                }, 'styleSizeFont'])
            }
        },
        changeTypeFont() {
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                this.updateAttrElement([{
                    id: elemTemp.id,
                    styleTypeFont: this.typeFont
                }, 'styleTypeFont'])
            }
        },
        changeSizeCover() {
            if(this.sizeTemp=='custom'){
               this.heightTemp = 150;
            }else{
                this.heightTemp = '';
            }

            let elemTemp = this.getSelectElement
            if (elemTemp) {
                this.updateAttrElement([{
                    id: elemTemp.id,
                    sizeCover: this.sizeTemp
                }, 'sizeCover'])
                this.updateAttrElement([{
                    id: elemTemp.id,
                    heightElem: this.heightTemp
                }, 'heightElem'])
            }
        },
        changeHeight() {
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                if (elemTemp.heightElem) elemTemp.heightElem = this.heightTemp
                this.updateAttrElement([{
                    id: elemTemp.id,
                    heightElem: this.heightTemp
                }, 'heightElem'])
            }
        },
        changeWidth() {
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                this.updateAttrElement([{
                    id: elemTemp.id,
                    widthElem: this.widthTemp
                }, 'widthElem'])
            }
        },
        changePosX() {
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                this.updateAttrElement([{
                    id: elemTemp.id,
                    axisX: this.xTemp
                }, 'axisX'])
            }
        },
        changePosY() {
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                if (elemTemp.axisY) elemTemp.axisY = this.yTemp
                this.updateAttrElement([{
                    id: elemTemp.id,
                    axisY: this.yTemp
                }, 'axisY'])
            }
        },
        changeBackgroundType() {
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                this.updateAttrElement([{
                    id: elemTemp.id,
                    styleBackgroundType: this.backgroundType
                }, 'styleBackgroundType'])
            }
        },
        changeUrlParamUser(){
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                if (elemTemp.urlParamUser) elemTemp.urlParamUser = this.urlParamUser
                this.updateAttrElement([{
                    id: elemTemp.id,
                    urlParamUser: this.urlParamUser
                }, 'urlParamUser'])
            }
        },
        deleteElement() {
            let elemTemp = this.getSelectElement
            let listTemp = this.getListElement

            if (elemTemp != null) {
                let indexElement = null
                for (let i = 0; i < listTemp.length; i++) {
                    if (listTemp[i].id == elemTemp.id) {
                        indexElement = i
                    }
                }
                listTemp.splice(indexElement, 1);
            }
            this.removeSelectElement()
            this.setIsContainer(false)
            this.removeListParent()
            this.removeList2Add()
        },
        async saveElement() {
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                if (this.backgroundImage != null && this.backgroundColor == null) {
                    this.backgroundImage = await this.getImgUrl(this.backgroundImage)
                    this.setBackgroundImage();
                }
            }
        },
        swatchStyleBackground(color, menu) {
            return {
                backgroundColor: color,
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                borderRadius: menu ? '50%' : '4px',
                border: '1px solid',
                transition: 'border-radius 200ms ease-in-out'
            }
        },
        swatchStyleText(color, menu) {
            return {
                backgroundColor: color,
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                borderRadius: menu ? '50%' : '4px',
                border: '1px solid',
                transition: 'border-radius 200ms ease-in-out'
            }
        },
        createBase64Image() {
            if (this.backgroundImageFile != null) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.backgroundImage = event.target.result;
                    this.setBackgroundImage();
                }
                reader.readAsDataURL(this.backgroundImageFile);
            }
        },
        debug() {
            console.log(this.getSelectElement);
            console.log(JSON.parse(JSON.stringify(this.listElements)));
        },
        setBackgroundImage() {
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                this.updateAttrElement([{
                    id: elemTemp.id,
                    styleImageBackground: this.backgroundImage
                }, 'styleImageBackground'])
                this.updateAttrElement([{
                    id: elemTemp.id,
                    styleColorBackground: this.backgroundColor
                }, 'styleColorBackground'])
                this.updateAttrElement([{
                    id: elemTemp.id,
                    styleBackgroundType: this.backgroundType
                }, 'styleBackgroundType'])
            }
        },
        checkCtrl(ctrl) {
            if (this.getSelectElement != null) {
                if (this.controls[this.getSelectElement.type] != undefined) {
                    return this.controls[this.getSelectElement.type].includes(ctrl);
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        ...mapMutations([
            'setIsContainer',
            'addListParent',
            'removeListParent',
            'addElements2ListParent',
            'removeElements2ListParent',
            'setSelectElement',
            'removeSelectElement',
            'addList2Add',
            'removeList2Add',
            'addElements2List2Add',
            'removeElements2List2Add',
            'updateAttrElement'
        ])
    },
    watch: {
        getAttrElement(newCount) {
            this.urlTemp = []
            this.urlLoggedTemp = [];
            //TEXT
            this.textTemp = []
            this.typeFont = ''
            this.sizeFont = ''
            this.backgroundColor = ''
            this.backgroundImage = ''
            this.textColor = ''
            this.justifyContent = ''
            this.alignItems = ''
            //SECTION
            this.sizeTemp = ''
            //ALL
            this.xTemp = ''
            this.yTemp = ''
            this.heightTemp = ''
            this.widthTemp = ''
            if (newCount) {
                //EMBED
                if (newCount.type == 'img') {
                    if (newCount.src) this.urlImgTemp = newCount.src
                } else {
                    if (newCount.src) this.urlTemp = newCount.src
                }
                //IFRAME
                if (newCount.urlParamUser) this.urlParamUser = newCount.urlParamUser;
                if (newCount.src2) this.urlLoggedTemp = newCount.src2
                //TEXT
                if (newCount.text) this.textTemp = newCount.text
                if (newCount.styleTypeFont) this.typeFont = newCount.styleTypeFont
                if (newCount.styleSizeFont) this.sizeFont = newCount.styleSizeFont
                if (newCount.styleImageBackground) this.backgroundImage = newCount.styleImageBackground
                if (newCount.styleColorBackground) this.backgroundColor = newCount.styleColorBackground
                if (newCount.styleBackgroundType) this.backgroundType = newCount.styleBackgroundType
                if (newCount.styleColorText) this.textColor = newCount.styleColorText
                if (newCount.justifyContent) this.justifyContent = newCount.justifyContent
                if (newCount.alignItems) this.alignItems = newCount.alignItems
                //ALL
                if (newCount.axisX) this.xTemp = newCount.axisX
                if (newCount.axisY) this.yTemp = newCount.axisY
                if (newCount.heightElem) this.heightTemp = newCount.heightElem
                if (newCount.widthElem) this.widthTemp = newCount.widthElem
                //SECTION
                if (newCount.sizeCover) this.sizeTemp = newCount.sizeCover
            }
        },
        menuBackgroundColor() {
            if (!this.menuBackgroundColor) {
                let elemTemp = this.getSelectElement
                if (elemTemp) {
                    this.updateAttrElement([{
                        id: elemTemp.id,
                        styleColorBackground: this.backgroundColor
                    }, 'styleColorBackground'])
                }
            }
        },
        menuTextColor() {
            if (!this.menuTextColor) {
                let elemTemp = this.getSelectElement
                if (elemTemp) {
                    this.updateAttrElement([{
                        id: elemTemp.id,
                        styleColorText: this.textColor
                    }, 'styleColorText'])
                }
            }
        },
        justifyContent() {
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                this.updateAttrElement([{
                    id: elemTemp.id,
                    justifyContent: this.justifyContent
                }, 'justifyContent'])
            }
        },
        alignItems() {
            let elemTemp = this.getSelectElement
            if (elemTemp) {
                this.updateAttrElement([{
                    id: elemTemp.id,
                    alignItems: this.alignItems
                }, 'alignItems'])
            }
        },
    },
    computed: {
        ...mapState([
            'urlProd',
            'urlTest',
            'isProd',
            'userInit',
        ]),
        ...mapGetters([
            'getSelectElement',
            'getListElement',
            'getIsContainer',
            'getListElement2Add',
            'getAttrElement',
        ]),
    },
    created: async function () {
        // if (this.isProd) {
            this.idUser = this.userInit.id
        // }
        //  else {
        //     this.idUser = 'a16505ba-f7f2-415a-8f80-b8e9bd36144b'
        // }
        this.overlay = true
        let result = await fetch(this.isProd ? this.urlProd + 'api/pages/edit/' + this.$route.params.id + '/' + this.idUser : this.urlTest + 'api/pages/edit/' + this.$route.params.id + '/' + this.idUser).then((res) => res.json())
        this.overlay = false
        if (result.success && result.code == 200) {
            if (result.data[0].structure != null) {
                this.listElements = JSON.parse(result.data[0].structure)
            }
            if (result.data[0].settings != null) {
                this.settingsSite = JSON.parse(result.data[0].settings)
                if (this.settingsSite.language) {
                    this.settingsSite.language = this.settingsSite.language.split(',')
                    this.listLanguages = JSON.parse(JSON.stringify(this.settingsSite.language))
                }
                for (let m = 0; m < this.listLanguages.length; m++) {
                    this.listDummyImgs.push('../assets/dummy.png')
                    this.listDummyText.push('Texto')
                }
                if (this.settingsSite.currency) this.settingsSite.currency = this.settingsSite.currency.split(',')
            }
            this.name = result.data[0].name
            this.idSite = result.data[0].id_site
            this.idPage = result.data[0].id
        }

        this.getSelectElement = null;
    },
    metaInfo() {
        return {
            title: `${this.$route.name}`,
            // meta: [
            //   { name: 'description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline },
            //   { property: 'og:title', content: this.userData.name + ' - Epiloge' },
            //   { property: 'og:site_name', content: 'Epiloge' },
            //   { property: 'og:description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline },
            //   { property: 'og:type', content: 'profile' },
            //   { property: 'og:url', content: 'https://epiloge.com/@' + this.userData.username },
            //   { property: 'og:image', content: this.aws_url + '/users/' + this.userData.profileurl + '-main.jpg' }
            // ]
        }
    },
}
</script>

<style lang="css">
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700& display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700& display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;600;700&display=swap');

.selected-element {
    border: 2px dashed #c3340c;
}

.bg-gray {
    background-color: #d3d3d3;
}

.h-100 {
    height: 100%;
}

.container-elements {
    height: calc(100% - 30px);
    background-color: white;
    padding: 0;
    overflow-y: auto;
}</style>