<template>
    <v-dialog v-model="dialogDelete" persistent max-width="30%">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ stateName }}</span>
            </v-card-title>
            <v-card-text>
                ¿Desea eliminar este registro?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="confirmDelete(false)" class="mr-4">
                    Cancelar
                </v-btn>
                <v-btn color="red darken-1" text @click="confirmDelete(true)">
                    Eliminar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import vuetify from '../plugins/vuetify'

export default {
    vuetify,
    props: ['dialogDelete', 'stateName'],
    methods: {
        confirmDelete(result) {
            this.$emit("confirmDelete", result)
        }
    }
}
</script>