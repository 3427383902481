<template>
    <v-dialog v-model="dialogSettings" persistent max-width="30%">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ stateName }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col v-for="item in filterStructure" lg="6" md="12" :title="item.text" v-bind:key="item.id">
                            <template v-if="item.type == 'string'">
                                <v-text-field v-if="item.value != 'description' && item.value != 'email'"
                                    :label="item.text" v-model="item.data"
                                    :required="item.required"></v-text-field>
                                <v-text-field v-if="item.value == 'email'" :label="item.text" v-model="item.data"
                                    :required="item.required">
                                </v-text-field>
                            </template>
                            <template v-if="item.type == 'integer'">
                                <v-text-field :label="item.text"
                                    v-if="item.value != 'celular'" v-model="item.data" type="number"
                                    :required="item.required" :disabled="item.text == 'Id'"></v-text-field>
                                <v-text-field v-if="item.value == 'celular'" :label="item.text" v-model="item.data"
                                    :required="item.required">
                                </v-text-field>
                            </template>
                            <template v-if="item.type == 'double'">
                                <v-text-field :label="item.text" v-model="item.data"
                                    type="number" :required="item.required"></v-text-field>
                            </template>
                            <template v-if="item.type == 'select'">
                                <v-autocomplete v-model="item.data" :label="item.text"
                                    :items="item.options" :item-text="text" :item-value="value" :multiple="item.multiple">
                                </v-autocomplete>
                            </template>
                            <template v-if="item.type == 'checkbox'">
                                <v-checkbox v-model="item.data" :label="item.text"></v-checkbox>
                            </template>
                            <template v-if="item.type=='file'">
                                <v-file-input v-model="item.data" :label="item.text"></v-file-input>
                            </template> 
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="saveConfig(null)" class="mr-4">
                    Cancelar
                </v-btn>
                <v-btn text @click="saveConfig(structureTable)">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import vuetify from '../plugins/vuetify'

export default {
    vuetify,
    props: ['dialogSettings', 'structureTable', 'stateName'],
    computed: {
        filterStructure () {
            return this.structureTable.filter(e => e.show)
        }
    },
    methods: {
        clearData() {
            for (let index = 0; index < this.structureTable.length; index++) {
                this.structureTable[index]["data"] = '';
            }
        },
        saveConfig(result) {
            if(result == null) {
                this.clearData()
            }
            this.$emit("saveConfig", result)
        }
    }
}
</script>