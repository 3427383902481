<template>
    <div class="d-flex text-center flex-column flex-md-row flex-grow-1">
        <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
            <div class="layout-content ma-auto w-full">
                <v-card class="text-center pa-5" min-width="400">
                    <v-img src="../assets/icon_color.png" max-height="75" contain></v-img>
                    <v-card-title class="justify-center text-h5 mb-2">
                        Iniciar sesión
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <v-form ref="form">
                            <v-text-field v-model="email" :rules="emailRules" label="Correo electrónico" required>
                            </v-text-field>
                            <v-text-field v-model="password" type="password" :rules="passwordRules" label="Contraseña"
                                required>
                            </v-text-field>
                            <v-btn depressed block color="soft" class="white--text mt-2" @click="login">
                                Acceder
                            </v-btn>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text class="soft--text" to="/">
                            Volver al inicio
                        </v-btn>
                        <v-btn text class="soft--text" to="/recovery">
                            Olvidé mi contraseña
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </div>
        <v-snackbar v-model="snackbar" color="soft" :timeout="timeout"> {{ textSnackBar }}
            <template v-slot:action="{ attrs }">
                <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                    <v-icon color="secondary">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-overlay :value="overlay" z-index="300" opacity="0.95">
            <v-progress-circular indeterminate color="original" size="120">
                <v-img src="../assets/icon_color.png" max-height="80" contain></v-img>
            </v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>


import vuetify from '../plugins/vuetify'
import { mapState, mapMutations } from 'vuex'

export default {
    name: 'Login',
    vuetify,
    data() {
        return {
            email: null,
            password: null,
            snackbar: false,
            overlay: false,
            textSnackBar: '...',
            timeout: 2000,
            emailRules: [
                v => !!v || 'Es obligatorio',
                v => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(v) || 'Debe ser un correo valido'
                }
            ],
            passwordRules: [
                v => !!v || 'Es obligatorio'
            ],
        }
    },
    computed: {
        ...mapState([
            'urlProd',
            'urlTest',
            'isProd',
            'isLogin'
        ]),
    },
    methods: {
        async login() {
            var data = {
                email: this.email,
                password: this.password
            };
            this.overlay = true
            let result = await fetch(this.isProd ? this.urlProd + 'api/login' : this.urlTest + 'api/login', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then((res) => res.json())
            this.overlay = false

            if (result.length > 0) {
                this.isLogged()
                this.saveDataLoggin(result[0])
                this.$router.push({ name: 'Gestión de Usuarios' })
            } else {
                this.textSnackBar = 'Usuario o contraseña incorrecto'
                this.snackbar = true
            }
        },
        ...mapMutations([
            'isLogged',
            'saveDataLoggin'
        ])
    },
    metaInfo() {
        return {
            title: `${this.$route.name}`,
            // meta: [
            //   { name: 'description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline },
            //   { property: 'og:title', content: this.userData.name + ' - Epiloge' },
            //   { property: 'og:site_name', content: 'Epiloge' },
            //   { property: 'og:description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline },
            //   { property: 'og:type', content: 'profile' },
            //   { property: 'og:url', content: 'https://epiloge.com/@' + this.userData.username },
            //   { property: 'og:image', content: this.aws_url + '/users/' + this.userData.profileurl + '-main.jpg' }
            // ]
        }
    },
}
</script>
