var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editable-element",class:[_vm.attrs.className != null ? _vm.attrs.className : ''],style:([
        {display: 'flex'},
        _vm.attrs.styleTypeFont != null ? { fontFamily: _vm.attrs.styleTypeFont } : '',
        _vm.attrs.styleWeightFont != null ? { fontWeight: _vm.attrs.styleWeightFont } : '', 
        _vm.attrs.styleSizeFont != null ? { fontSize: _vm.attrs.styleSizeFont } : '', 
        _vm.attrs.styleBackgroundType == 'imagen' ?  { backgroundImage: 'url(' + _vm.attrs.styleImageBackground + ')' } : { backgroundColor: _vm.attrs.styleColorBackground },
        _vm.attrs.styleColorText != null ? { color: _vm.attrs.styleColorText } : '', 
        _vm.attrs.justifyContent != null ? { justifyContent: _vm.attrs.justifyContent } : '', 
        _vm.attrs.justifyContent != null ? { textAlign: _vm.attrs.justifyContent } : '', 
        _vm.attrs.alignItems != null ? { alignItems: _vm.attrs.alignItems } : '', 
        _vm.translate != null ? { translate: _vm.translate } : '',
        _vm.attrs.heightElem != null ? !isNaN(_vm.attrs.heightElem) ? { height: _vm.attrs.heightElem + 'px' }  : { height: _vm.attrs.heightElem } : '', 
        _vm.attrs.widthElem != null ? !isNaN(_vm.attrs.widthElem) ? { width: _vm.attrs.widthElem + 'px' }  : { width: _vm.attrs.widthElem } : '',
        {position: 'absolute'}
    ]),attrs:{"id":_vm.id},on:{"click":function($event){return _vm.clickElement($event)}}},[_vm._v(" "+_vm._s(_vm.attrs.text[_vm.attrs.isLang ? _vm.attrs.isLang : 0])+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }