<template>
  <v-main>
    <v-container>
      <v-row class="mt-4">
        <v-col cols="4" v-for="item in listUsers" v-bind:key="item.id">
          <v-card color="original" dark>
            <v-row>
              <v-col cols="9">
                <v-card-title class="text-h6">
                  {{ item.name }}
                </v-card-title>
                <v-card-subtitle>{{ item.email }}</v-card-subtitle>
                <v-chip class="ma-2" color="soft" label small text-color="white">{{ item.id_role == '1' ?
                  'SuperAdministrador' : 'Administrador' }}</v-chip>
              </v-col>
              <v-col cols="3" class="align-self-center text-center">
                <v-avatar color="original darken-1">
                  <v-icon dark>
                    mdi-account-circle
                  </v-icon>
                </v-avatar>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-btn color="white" text @click="loadDialog(item.id)">
                Editar
              </v-btn>
              <v-btn color="white" text @click="loadDialogDelete(item.id)">
                Eliminar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="2" class="align-center d-flex justify-center">
          <v-fab-transition>
            <v-btn color="accent" dark fab @click="loadDialog()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-col>
      </v-row>

    </v-container>
    <Overlay :overlay="overlay"></Overlay>
    <Dialog :dialog="dialog" :structureTable="structureUser" :stateName="nombreUsuario" @saveData="saveData"></Dialog>
    <DialogMsg :dialogDelete="dialogDelete" :stateName="nombreUsuario" @confirmDelete="confirmDelete"></DialogMsg>
</v-main>
</template>

<script>
import vuetify from '../plugins/vuetify'
import Overlay from '../components/Overlay.vue'
import Dialog from '../components/Dialog.vue'
import DialogMsg from '../components/DialogMsg.vue'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  vuetify,
  data: () => ({
    listUsers: [],
    idSelected: null,
    nombreUsuario: null,
    overlay: false,
    dialog: false,
    dialogDelete: false,
    structureUser: [
      {
        "value": "id",
        "type": "string",
        "text": "Id",
        "required": false,
        "show": false,
        "data": "",
      },
      {
        "value": "name",
        "type": "string",
        "text": "Nombre",
        "required": true,
        "show": true,
        "data": "",
      },
      {
        "value": "email",
        "type": "string",
        "text": "Correo",
        "required": true,
        "show": true,
        "data": "",
      },
      {
        "value": "id_role",
        "type": "select",
        "text": "Rol",
        "required": true,
        "show": true,
        "data": "",
        "options": [
          {
            text: "Administrador",
            value: '2'
          }
        ]
      }
    ]
  }),
  components: {
    Overlay,
    DialogMsg,
    Dialog
  },
  methods: {
    loadDialog(id) {
      if (id != null) {
        this.idSelected = id
        for (let i = 0; i < this.listUsers.length; i++) {
          if (this.listUsers[i].id == id) {
            for (let j = 0; j < Object.keys(this.listUsers[i]).length; j++) {
              for (let k = 0; k < this.structureUser.length; k++) {
                if (this.structureUser[k]["value"] == Object.keys(this.listUsers[i])[j]) {
                  this.structureUser[k]["data"] = this.listUsers[i][Object.keys(this.listUsers[i])[j]]
                }
              }
            }
            this.nombreUsuario = this.listUsers[i].name
          }
        }
      } else {
        for (let index = 0; index < this.structureUser.length; index++) {
          this.structureUser[index]["data"] = '';
        }
      }
      this.dialog = true
    },
    loadDialogDelete(id) {
      if (id != null) {
        this.idSelected = id
        for (let i = 0; i < this.listUsers.length; i++) {
          if (this.listUsers[i].id == id) {
            this.nombreUsuario = this.listUsers[i].name
          }
        }
      }
      this.dialogDelete = true
    },
    async confirmDelete(e) {
      if (e) {
        this.dialogDelete = false
        var data = {
          id: this.idSelected
        }
        this.overlay = true
        let url = this.isProd ? this.urlProd + 'api/users/delete' : this.urlTest + 'api/users/delete'
        let result = await fetch(url, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }).then((res) => res.json())
        if (result.success && result.code == 200) {
          let result = await fetch(this.isProd ? this.urlProd + 'api/users/get' : this.urlTest + 'api/users/get').then((res) => res.json())
          this.overlay = false
          if (result.success && result.code == 200) {
            this.listUsers = result.data
          }
        } else {
          console.log('Ocurrio un error', result);
          this.overlay = false
        }
      } else {
        this.dialogDelete = false
      }
    },
    async saveData(e) {
      if (e == null) {
        this.dialog = false
      } else {
        this.dialog = false
        var data = {}
        for (let i = 0; i < e.length; i++) {
          if (e[i]['data'] != '') {
            data[e[i]['value']] = e[i]['data']
          }
        }
        this.overlay = true
        let isCreateOrUpdate = !data.id ? '/create' : '/update'
        let url = this.isProd ? this.urlProd + 'api/users' + isCreateOrUpdate : this.urlTest + 'api/users' + isCreateOrUpdate
        let result = await fetch(url, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }).then((res) => res.json())
        if (result.success && result.code == 200) {
          let result = await fetch(this.isProd ? this.urlProd + 'api/users/get' : this.urlTest + 'api/users/get').then((res) => res.json())
          this.overlay = false
          if (result.success && result.code == 200) {
            this.listUsers = result.data
          }
        } else {
          console.log('Ocurrio un error', result);
          this.overlay = false
        }
      }
    }
  },
  computed: {
    ...mapState([
      'urlProd',
      'urlTest',
      'isProd',
      'isLogin'
    ]),
  },
  created: async function () {
    this.overlay = true
    let result = await fetch(this.isProd ? this.urlProd + 'api/users/get' : this.urlTest + 'api/users/get').then((res) => res.json())
    this.overlay = false
    if (result.success && result.code == 200) {
      this.listUsers = result.data
    }
  },
  metaInfo() {
    return {
      title: `${this.$route.name}`,
      // meta: [
      //   { name: 'description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline },
      //   { property: 'og:title', content: this.userData.name + ' - Epiloge' },
      //   { property: 'og:site_name', content: 'Epiloge' },
      //   { property: 'og:description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline },
      //   { property: 'og:type', content: 'profile' },
      //   { property: 'og:url', content: 'https://epiloge.com/@' + this.userData.username },
      //   { property: 'og:image', content: this.aws_url + '/users/' + this.userData.profileurl + '-main.jpg' }
      // ]
    }
  },
}
</script>
