<template>
    <v-overlay :value="overlay" z-index="300" opacity="0.95">
        <v-progress-circular indeterminate color="original" size="120">
            <v-img src="../assets/icon_color.png" width="80"></v-img>
        </v-progress-circular>
    </v-overlay>
</template>

<script>
import vuetify from '../plugins/vuetify'

export default {
    vuetify,
    props: ['overlay']
}
</script>