<template>
    <v-dialog v-model="dialogHeader" persistent max-width="35%">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ stateName }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col v-for="item in filterStructure" lg="6" md="12" :title="item.text" v-bind:key="item.id">
                            <template v-if="item.type == 'color'">
                                <div>
                                    <p class="mb-1">{{ item.text }}</p>
                                    <div class="shrink" style="min-width: 220px;">
                                        <v-text-field v-model="item.data" hide-details class="ma-0 pa-0" solo>
                                            <template v-slot:append>
                                                <v-menu v-model="item.dialog" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                                    <template v-slot:activator="{ on }">
                                                        <div :style="swatchStyle(item.data, item.dialog)" v-on="on" />
                                                    </template>
                                                    <v-card>
                                                        <v-card-text class="pa-0">
                                                            <v-color-picker v-model="item.data" mode="hexa"
                                                                />
                                                        </v-card-text>
                                                    </v-card>
                                                </v-menu>
                                            </template>
                                        </v-text-field>
                                    </div>
                                </div>
                            </template>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="saveHeader(null)" class="mr-4">
                    Cancelar
                </v-btn>
                <v-btn text @click="saveHeader(structureTable)">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import vuetify from '../plugins/vuetify'

export default {
    vuetify,
    props: ['dialogHeader', 'structureTable', 'stateName'],
    data: () => ({
        menuColor: false,
        elementColor: '#076932FF',
    }),
    computed: {
        filterStructure() {
            return this.structureTable.filter(e => e.show)
        },
    },
    methods: {
        clearData() {
            for (let index = 0; index < this.structureTable.length; index++) {
                this.structureTable[index]["data"] = '';
            }
        },
        saveHeader(result) {
            if (result == null) {
                this.clearData()
            }
            this.$emit("saveHeader", result)
        },
        swatchStyle(color, menu) {
            return {
                backgroundColor: color,
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                borderRadius: menu ? '50%' : '4px',
                border: '1px solid',
                transition: 'border-radius 200ms ease-in-out'
            }
        },
    }
}
</script>