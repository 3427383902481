<template>
  <div id="app">
    <v-app>
      <NavBar :name="name" v-if="!$route.path.includes('/login') && !$route.path.includes('/reset') && !$route.path.includes('/pg')"></NavBar>
      <router-view style="height: calc(100% - 119px);"/>
      <Footer v-if="!$route.path.includes('/pg')"></Footer>
    </v-app>
</div>
</template>

<script>
import NavBar from "./components/NavBar.vue"
import Footer from "./components/Footer.vue"
export default {
  name: "App",
  components: {
    NavBar,
    Footer
  },
  data: () => ({
    name: null,
  }),
  created: function () {
    this.name = this.$route.name    
  },
  updated: function () {
    this.name = this.$route.name
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  max-height: 100vh;
  width: 100%;
  margin: 0;
}

html {
  overflow-y: auto !important;
}

* {
  box-sizing: border-box;
}
</style>