<template>
    <div 
    class="container-element-snap" 
    :class="[
    attrs.className != null ? attrs.className : '',
    attrs.sizeCover != null ? attrs.sizeCover : '']" 
    :id="id" 
    @click="clickContainer($event)" 
    :style="[
        attrs.styleColorBackground != null ? { backgroundColor: attrs.styleColorBackground } : '',
        attrs.styleImageBackground != null ? { backgroundImage: 'url(' + attrs.styleImageBackground + ')' } : '',
    ]">
        <div class="row container-fluid">
            <section class="py-5 col-md-6 offset-md-3 col-sm-10 offset-sm-1 col-10 offset-1">
                <TextComponent :id="attrs.children[0].id" :attrs="attrs.children[0].attrs" :listParent="listElements"></TextComponent>
                <v-divider></v-divider>
                <TextComponent :id="attrs.children[1].id" :attrs="attrs.children[1].attrs" :listParent="listElements"></TextComponent>
                <div class="form-floating mb-3">
                    <InputComponent :id="attrs.children[3].id" :attrs="attrs.children[3].attrs" :listParent="listElements"></InputComponent>

                    <TextComponent :id="attrs.children[2].id" :attrs="attrs.children[2].attrs" :listParent="listElements"></TextComponent>
                    <!-- <div class="invalid-feedback" data-feedback="required"
                        :style="[attrs.styleColorTextHelp != null ? { color: attrs.styleColorTextHelp } : '']">El correo es
                        obligatorio</div>
                    <div class="invalid-feedback" data-feedback="valid"
                        :style="[attrs.styleColorTextHelp != null ? { color: attrs.styleColorTextHelp } : '']">El correo no
                        es
                        valido</div> -->
                </div>
                <div class="d-grid gap-2">
                    <ButtonComponent :id="attrs.children[4].id" :attrs="attrs.children[4].attrs" :listParent="listElements"></ButtonComponent>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '../components/ButtonComponent.vue'
import TextComponent from '../components/TextComponent.vue'
import InputComponent from '../components/InputComponent.vue'
import { mapMutations, mapGetters } from 'vuex'
import interact from 'interactjs'
import vuetify from '../plugins/vuetify'
export default {
    props: ['attrs', 'id', 'listParent'],
    vuetify,
    data() {
        return {
            isDragging: false,
            isSizing: false,
            listElements: this.attrs.children
        }
    },
    components: {
        ButtonComponent,
        TextComponent,
        InputComponent,
    },
    methods: {
        clickContainer: function (event) { // CONTAINER
            if (!this.attrs.isPublish) {
                this.isSelect()
                event.stopPropagation()
            }
        },
        isSelect: function (isDeselect = true) {
            if (!this.isDragging && !this.isSizing) {
                let elemTemp = this.getSelectElement
                if (elemTemp == null) { //Vacio
                    this.$el.classList.add('selected-element')
                    this.setSelectElement(this)
                    this.setIsContainer(false)
                    this.addListParent(this.listParent)
                    this.loadAttr()
                } else if (this.id == elemTemp.id) { //Deseleccion
                    if (isDeselect) {
                        this.$el.classList.remove('selected-element')
                        this.removeSelectElement()
                        this.setIsContainer(false)
                        this.removeListParent()
                        this.removeAttrElement()
                    }
                } else { //Otro seleccionado
                    elemTemp.$el.classList.remove('selected-element')
                    this.$el.classList.add('selected-element')
                    this.setSelectElement(this)
                    this.setIsContainer(false)
                    this.addListParent(this.listParent)
                    this.loadAttr()
                }
            }
        },
        loadAttr: function () {
            let objAttr = {
                heightElem: this.attrs.heightElem,
                widthElem: this.attrs.widthElem,
                axisX: this.attrs.axisX,
                axisY: this.attrs.axisY,
                styleColorBackground: this.attrs.styleColorBackground,
                styleImageBackground: this.attrs.styleImageBackground,
                sizeCover: this.attrs.sizeCover, //Three Sizes
            }
            this.setAttrElement(objAttr)
        },
        dragMove: function (event) {
            this.isSelect(false)
            this.isDragging = true
            this.attrs.axisX += event.dx
            this.attrs.axisY += event.dy
            this.$emit("hoverParent", true)
        },
        dragEnd: function () {
            var parent = this.$el.parentNode
            let widthPx = (parent.clientWidth * parseFloat(this.attrs.axisX)) / 100
            let widthPercentage = (100 * widthPx) / parent.clientWidth + '%'
            this.attrs.axisX = widthPercentage
            this.$emit("hoverParent", false)
            this.updateAttrElement([{
                id: this.id,
                axisX: this.attrs.axisX
            }, 'axisX'])
            this.updateAttrElement([{
                id: this.id,
                axisY: this.attrs.axisY
            }, 'axisY'])
            let thisElement = this
            let elemTemp = this.getSelectElement
            if (elemTemp.id == this.id) {
                this.loadAttr()
            }
            setTimeout(() => { thisElement.isDragging = false }, 200)
        },
        ...mapMutations([
            'setIsContainer',
            'addListParent',
            'removeListParent',
            'addElements2ListParent',
            'removeElements2ListParent',
            'setSelectElement',
            'removeSelectElement',
            'addList2Add',
            'removeList2Add',
            'addElements2List2Add',
            'removeElements2List2Add',
            'setAttrElement',
            'removeAttrElement',
            'updateAttrElement'
        ])
    },
    mounted: function () {
        if (!this.attrs.isPublish) {
            var parent = this.$el.parentNode
            var element = this.$el
            var elementThis = this
            if (element) {
                interact(element)
                    .resizable({
                        edges: { left: true, right: true, bottom: true, top: true },
                        listeners: {
                            move(event) {
                                elementThis.isSizing = true
                                var target = event.target
                                target.style.width = event.rect.width + 'px'
                                target.style.height = event.rect.height + 'px'
                            }
                        },
                        onend: function (event) {
                            var target = event.target
                            let widthPercentage = (100 * event.rect.width) / parent.clientWidth

                            target.style.width = widthPercentage + '%'
                            elementThis.updateAttrElement([{
                                id: elementThis.id,
                                widthElem: widthPercentage + '%'
                            }, 'widthElem'])
                            elementThis.updateAttrElement([{
                                id: elementThis.id,
                                heightElem: event.rect.height
                            }, 'heightElem'])
                            let elemTemp = elementThis.getSelectElement
                            if (elemTemp.id == elementThis.id) {
                                elementThis.loadAttr()
                            }
                            setTimeout(() => { elementThis.isSizing = false }, 200)
                        },
                        modifiers: [
                            interact.modifiers.restrictEdges({
                                outer: element.parentNode,
                            }),
                            interact.modifiers.restrictSize({
                                min: { width: 100, height: 50 }
                            })
                        ],
                        inertia: true
                    })
                    .draggable({
                        modifiers: [
                            interact.modifiers.snap({
                                targets: [
                                    interact.snappers.grid({ x: 10, y: 10 })
                                ],
                                range: Infinity,
                                relativePoints: [{ x: 0, y: 0 }]
                            }),
                            interact.modifiers.restrict({
                                restriction: element.parentNode,
                                elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
                                endOnly: true
                            })
                        ],
                        inertia: true
                    })
                    .on('dragmove', this.dragMove)
                    .on('dragend', this.dragEnd)
            }
        }
    },
    computed: {
        translate() {
            let ejeX = 0
            let ejeY = 0
            if (isNaN(this.attrs.axisX)) {
                ejeX = this.attrs.axisX
            } else {
                ejeX = this.attrs.axisX + 'px'
            }
            if (isNaN(this.attrs.axisY)) {
                ejeY = this.attrs.axisY
            } else {
                ejeY = this.attrs.axisY + 'px'
            }
            return `${ejeX} ${ejeY}`
        },
        ...mapGetters([
            'getSelectElement',
            'getListElement',
            'getIsContainer',
            'getListElement2Add',
            'getAttrElement',
        ])
    },
}
</script>

<style>
.small {
    height: 300px;
}

.medium {
    height: 500px;
}

.large {
    height: 700px;
}

.max {
    height: 100%;
}
</style>