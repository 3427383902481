<template>
    <v-main>
        <v-container>
            <v-row class="mb-4 mt-2" v-if="typeSite == 'landing' && listPages.length < 1 || typeSite == 'site'">
                <v-col cols="12" class="align-center d-flex justify-start">
                    <v-fab-transition>
                        <v-btn color="accent" dark fab @click="loadDialog()">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-fab-transition>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="mb-4 mt-2" lg="3" md="3" sm="6" v-for="item in listPages" v-bind:key="item.id">
                    <v-card color="primary" dark>
                        <v-row align="center" class="mx-0 mb-0">
                            <v-card-title class="text-h6">
                                {{ item.name }}
                                <div class="grey--text ms-4 font-12">
                                    /{{ item.url }}
                                </div>
                            </v-card-title>

                        </v-row>

                        <v-card-text class="pt-0">
                            <div class="my-2 text-subtitle-1">
                                {{ item.title }}
                            </div>
                            <v-chip color="orange" small>{{ item.status }}</v-chip>
                        </v-card-text>

                        <v-divider></v-divider>
                        <v-card-actions class="justify-center">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="white" icon :to="'/pages-edit/' + item.id">
                                        <v-icon dark v-bind="attrs" v-on="on">
                                            mdi-ruler-square-compass
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span class="font-12"> Diseñar Página</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="white" icon @click="loadDialog(item.id)">
                                        <v-icon dark v-bind="attrs" v-on="on">
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span class="font-12"> Editar Página</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="white" icon @click="loadDialogSEO(item.id)">
                                        <v-icon dark v-bind="attrs" v-on="on">
                                            mdi-google-analytics
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span class="font-12"> Editar SEO</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="white" icon @click="loadDialogDelete(item.id)">
                                        <v-icon dark v-bind="attrs" v-on="on">
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span class="font-12"> Borrar Página</span>
                            </v-tooltip>




                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <Overlay :overlay="overlay"></Overlay>
        <Dialog :dialog="dialog" :structureTable="structurePage" :stateName="nameTemplate" @saveData="saveData"></Dialog>
        <DialogMsg :dialogDelete="dialogDelete" :stateName="nombreUsuario" @confirmDelete="confirmDelete"></DialogMsg>
    </v-main>
</template>

<script>
import vuetify from '../plugins/vuetify'
import Overlay from '../components/Overlay.vue'
import Dialog from '../components/Dialog.vue'
import DialogMsg from '../components/DialogMsg.vue'
import { mapState } from 'vuex'

export default {
    name: 'ViewPages',
    vuetify,
    data: () => ({
        listPages: [],
        idUser: null,
        idSelected: null,
        idSite: null,
        typeSite: null,
        template: null,
        nameTemplate: null,
        overlay: false,
        dialog: false,
        dialogPage: false,
        dialogDelete: false,
        structurePage: [
            {
                "value": "id",
                "type": "string",
                "text": "Id",
                "required": false,
                "show": false,
                "data": "",
            },
            {
                "value": "id_site",
                "type": "string",
                "text": "Id Sitio",
                "required": false,
                "show": false,
                "data": "",
            },
            {
                "value": "name",
                "type": "string",
                "text": "Nombre",
                "required": true,
                "show": true,
                "data": "",
            },
            {
                "value": "title",
                "type": "string",
                "text": "Titulo (ES)",
                "required": false,
                "show": true,
                "data": "",
            },
            {
                "value": "title2",
                "type": "string",
                "text": "Titulo (EN)",
                "required": true,
                "show": true,
                "data": "",
            },
            {
                "value": "title3",
                "type": "string",
                "text": "Titulo (PT)",
                "required": true,
                "show": true,
                "data": "",
            },
            {
                "value": "url",
                "type": "string",
                "text": "Endpoint URL",
                "required": false,
                "show": true,
                "data": "",
            },
            {
                "value": "status",
                "type": "select",
                "text": "Activo",
                "required": false,
                "show": true,
                "data": "active",
                "options": [
                    {
                        text: "Activo",
                        value: 'active'
                    },
                    {
                        text: "Desactivada",
                        value: 'inactive'
                    },
                ]
            },
            {
                "value": "structure",
                "type": "string",
                "text": "Estructura",
                "required": false,
                "show": false,
                "data": "",
            },
            {
                "value": "init",
                "type": "boolean",
                "text": "Página Inicial",
                "required": false,
                "show": true,
                "data": false,
            },
            {
                "value": "profile",
                "type": "boolean",
                "text": "Página de perfil de usuario",
                "required": false,
                "show": true,
                "data": false,
            },
            {
                "value": "intoheader",
                "type": "boolean",
                "text": "Enlaza al Cabezote",
                "required": false,
                "show": true,
                "data": false,
            },
            {
                "value": "permission",
                "type": "select",
                "multiple": true,
                "text": "Permisos",
                "required": true,
                "show": true,
                "data": "",
                "options": [
                    {
                        text: "Suscrito",
                        value: '0'
                    },
                    {
                        text: "Usuario Abierto",
                        value: '1'
                    },
                    {
                        text: "Cliente",
                        value: '2'
                    },
                    {
                        text: "Negocio",
                        value: '3'
                    },
                ]
            },
            {
                "value": "order_page",
                "type": "select",
                "text": "Orden en el menú",
                "required": true,
                "show": true,
                "data": "",
                "options": []
            },
        ]
    }),
    components: {
        Overlay,
        DialogMsg,
        Dialog
    },
    methods: {
        loadDialogSEO(id) {
            if (id != null) {
                this.idSelected = id
                for (let i = 0; i < this.listPages.length; i++) {
                    if (this.listPages[i].id == id) {
                        this.template = JSON.parse(this.listPages[i].structure)
                        this.nameTemplate = this.listPages[i].name
                    }
                }
            }
            console.log(this.template);

            this.dialogPage = true
        },
        loadDialog(id) {
            if (id != null) {
                this.idSelected = id
                for (let i = 0; i < this.listPages.length; i++) {
                    if (this.listPages[i].id == id) {
                        for (let j = 0; j < Object.keys(this.listPages[i]).length; j++) {
                            for (let k = 0; k < this.structurePage.length; k++) {
          
                                if (this.structurePage[k]["value"] == Object.keys(this.listPages[i])[j]) {
                                    if (this.structurePage[k]["value"] == 'permission') {
                                        this.structurePage[k]["data"] = this.listPages[i][Object.keys(this.listPages[i])[j]].split(',')
                                    } else {
                                       
                                        this.structurePage[k]["data"] = this.listPages[i][Object.keys(this.listPages[i])[j]]
                                    }
                                }
                            }
                        }
                        this.nameTemplate = this.listPages[i].name
                    }
                }
            } else {
                for (let index = 0; index < this.structurePage.length; index++) {
                    this.structurePage[index]["data"] = '';
                    if (this.structurePage[index]['value'] == 'id_site') {
                        this.structurePage[index]["data"] = this.$route.params.id
                    }
                }
            }
            this.dialog = true
        },
        loadDialogDelete(id) {
            if (id != null) {
                this.idSelected = id
                for (let i = 0; i < this.listPages.length; i++) {
                    if (this.listPages[i].id == id) {
                        this.nombreUsuario = this.listPages[i].name
                    }
                }
            }
            this.dialogDelete = true
        },
        async confirmDelete(e) {
            if (e) {
                this.dialogDelete = false
                var data = {
                    id: this.idSelected,
                    id_site: this.$route.params.id
                }
                this.overlay = true
                let url = this.isProd ? this.urlProd + 'api/pages/delete' : this.urlTest + 'api/pages/delete'
                let result = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then((res) => res.json())
                if (result.success && result.code == 200) {
                    let result = await fetch(this.isProd ? this.urlProd + 'api/pages/get/' + this.$route.params.id + '/' + this.idUser : this.urlTest + 'api/pages/get/' + this.$route.params.id + '/' + this.idUser).then((res) => res.json())
                    this.overlay = false
                    if (result.success && result.code == 200) {
                        this.listPages = result.data.sort((a, b)=>a.order_page - b.order_page);
                    }
                } else {
                    console.log('Ocurrio un error', result);
                    this.overlay = false
                }
            } else {
                this.dialogDelete = false
            }
        },
        async saveData(e) {
            if (e == null) {
                this.dialog = false
            } else {
                this.dialog = false
                var data = {}
                for (let i = 0; i < e.length; i++) {
                    // if (e[i]['data'] != '') {
                        if (Array.isArray(e[i]['data'])) {
                            data[e[i]['value']] = e[i]['data'].join(',')
                        } else if(e[i]['type'] == 'boolean') {
                            if (e[i]['data'] != '') {
                                data[e[i]['value']] = e[i]['data']
                            } else {
                                data[e[i]['value']] = false
                            }
                        } else {
                            data[e[i]['value']] = e[i]['data']
                        }
                    // }
                }
                console.log(data);
                this.overlay = true
                let isCreateOrUpdate = !data.id ? '/create' : '/update'
                let url = this.isProd ? this.urlProd + 'api/pages' + isCreateOrUpdate : this.urlTest + 'api/pages' + isCreateOrUpdate
                let result = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then((res) => res.json())
                if (result.success && result.code == 200) {
                    let result = await fetch(this.isProd ? this.urlProd + 'api/pages/get/' + this.$route.params.id + '/' + this.idUser : this.urlTest + 'api/pages/get/' + this.$route.params.id + '/' + this.idUser).then((res) => res.json())
                    this.overlay = false
                    if (result.success && result.code == 200) {
                        this.listPages = result.data.sort((a, b)=>a.order_page - b.order_page);
                    }
                } else {
                    console.log('Ocurrio un error', result);
                    this.overlay = false
                }
            }
        },
        closePage() {
            this.dialogPage = false
        },
        setDefaultOrderOptions() {
            var o = [];
            for (let i = 1; i <= 100; i++) {
                o.push({
                    text: i,
                    value: i
                })
            }

            this.structurePage.find(item => item.value == 'order_page').options = o;
        }
    },
    computed: {
        ...mapState([
            'urlProd',
            'urlTest',
            'isProd',
            'isLogin',
            'userInit'
        ]),
    },
    created: async function () {
        // if(this.isProd) {
            this.idUser = this.userInit.id
        // }
        //  else {
        //     this.idUser = 'a16505ba-f7f2-415a-8f80-b8e9bd36144b'
        // }
        this.idSite = this.$route.params.id
        this.typeSite = this.$route.params.type
        this.overlay = true
        let result = await fetch(this.isProd ? this.urlProd + 'api/pages/get/' + this.$route.params.id + '/' + this.idUser : this.urlTest + 'api/pages/get/' + this.$route.params.id + '/' + this.idUser).then((res) => res.json())
        this.overlay = false
        if (result.success && result.code == 200) {
            this.listPages = result.data.sort((a, b)=>a.order_page - b.order_page);
            console.log(this.listPages);
        }
    },
    metaInfo() {
        return {
            title: `${this.$route.name}`,
            // meta: [
            //   { name: 'description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline },
            //   { property: 'og:title', content: this.userData.name + ' - Epiloge' },
            //   { property: 'og:site_name', content: 'Epiloge' },
            //   { property: 'og:description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline },
            //   { property: 'og:type', content: 'profile' },
            //   { property: 'og:url', content: 'https://epiloge.com/@' + this.userData.username },
            //   { property: 'og:image', content: this.aws_url + '/users/' + this.userData.profileurl + '-main.jpg' }
            // ]
        }
    },
    mounted(){
       this.setDefaultOrderOptions();
    }
}
</script>

<style>
.v-main {
    overflow-y: auto;
}
</style>