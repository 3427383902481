<template>
    <div :class="[attrs.className != null ? attrs.className : '']" :style="[
        translate != null ? { translate } : '',
        attrs.heightElem != null ? !isNaN(attrs.heightElem) ? { height: attrs.heightElem + 'px' }  : { height: attrs.heightElem } : '', 
        attrs.widthElem != null ? !isNaN(attrs.widthElem) ? { width: attrs.widthElem + 'px' }  : { width: attrs.widthElem } : '',
        {position: 'absolute'},
    ]" @click="clickElement($event)" >
        <!-- <v-text-field  color="attrs.styleColorText"
    :style="[
        attrs.styleTypeFont != null ? { fontFamily: attrs.styleTypeFont } : '',
        attrs.styleWeightFont != null ? { fontWeight: attrs.styleWeightFont } : '', 
        attrs.styleSizeFont != null ? { fontSize: attrs.styleSizeFont } : '', 
        attrs.styleColorBackground != null ? { backgroundColor: attrs.styleColorBackground } : '', 
        attrs.styleColorText != null ? { color: attrs.styleColorText } : '', 
        attrs.justifyContent != null ? { textAlign: attrs.justifyContent } : '', 
        attrs.isPublish ? {  pointerEvents: 'all' } : { pointerEvents: 'none' }
    ]"
    :placeholder="attrs.text[attrs.isLang ? attrs.isLang : 0]"
    :id="id" variant="outlined"></v-text-field> -->
    <input :style="[
        attrs.styleTypeFont != null ? { fontFamily: attrs.styleTypeFont } : '',
        attrs.styleWeightFont != null ? { fontWeight: attrs.styleWeightFont } : '', 
        attrs.styleSizeFont != null ? { fontSize: attrs.styleSizeFont } : '', 
        attrs.styleColorBackground != null ? { backgroundColor: attrs.styleColorBackground } : '', 
        attrs.styleColorText != null ? { color: attrs.styleColorText } : '', 
        attrs.justifyContent != null ? { textAlign: attrs.justifyContent } : '', 
        attrs.isPublish ? {  pointerEvents: 'all' } : { pointerEvents: 'none' }
    ]" type="text" 
    :placeholder="attrs.text[attrs.isLang ? attrs.isLang : 0]" :id="id" >
    </div>

</template>

<style>
input{
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
    padding-left: 8px;
    width: 100%;
    height: 100%;
    position: relative;
}

input::placeholder{
    color: v-bind('attrs.styleColorText');
    opacity: 0.5;
}
</style>
<!-- // attrs.isPublish ? {  pointerEvents: 'all' } : { pointerEvents: 'none' } :type="attrs.typeInput" -->

<!-- :disabled="!attrs.isPublish" -->


<script>
import { mapMutations, mapGetters } from 'vuex'
import interact from 'interactjs'
import vuetify from '../plugins/vuetify'
export default {
    props: ['attrs', 'id', 'listParent'],
    vuetify,
    data() {
        return {
            isDragging: false,
            isSizing: false,
            type: 'InputComponent'
        }
    },
    methods: {
        clickElement: function (event) { // ELEMENT
            if(!this.attrs.isPublish) {
                this.isSelect()
                event.stopPropagation()
            }
        },
        isSelect: function(isDeselect = true) {
            if(!this.isDragging) {
                let elemTemp = this.getSelectElement
                if (elemTemp == null) { //Vacio
                    this.$el.classList.add('selected-element')
                    this.setSelectElement(this)
                    this.setIsContainer(false)
                    this.addListParent(this.listParent)
                    this.loadAttr()
                } else if (this.id == elemTemp.id) { //Deseleccion
                    if(isDeselect) {
                        this.$el.classList.remove('selected-element')
                        this.removeSelectElement()
                        this.setIsContainer(false)
                        this.removeListParent()
                        this.removeAttrElement()
                    }
                } else { //Otro seleccionado
                    elemTemp.$el.classList.remove('selected-element')
                    this.$el.classList.add('selected-element')
                    this.setSelectElement(this)
                    this.setIsContainer(false)
                    this.addListParent(this.listParent)
                    this.loadAttr()
                }
            }
        },
        loadAttr: function () {
            let objAttr = {
                id: this.id,
                styleColorBackground: this.attrs.styleColorBackground,
                styleTypeFont: this.attrs.styleTypeFont,
                styleSizeFont: this.attrs.styleSizeFont,
                styleColorText: this.attrs.styleColorText,
                text: this.attrs.text,
                heightElem: this.attrs.heightElem,
                widthElem: this.attrs.widthElem,
                axisX: this.attrs.axisX,
                axisY: this.attrs.axisY,
            }
            this.setAttrElement(objAttr)
        },
        dragMove: function (event) {
            this.isSelect(false)
            this.isDragging = true
            this.attrs.axisX = parseFloat(this.attrs.axisX);
            this.attrs.axisY = parseFloat(this.attrs.axisY);
            this.attrs.axisX += event.dx
            this.attrs.axisY += event.dy
            this.$emit("hoverParent", true)
        },
        dragEnd: function () {
            // var parent = this.$el.parentNode
            // let widthPx = ( parent.clientWidth * parseFloat(this.attrs.axisX)) / 100
            // let widthPercentage = ( 100 * widthPx) / parent.clientWidth + '%'
            // this.attrs.axisX = widthPercentage
            // this.$emit("hoverParent", false)
            // this.updateAttrElement([{
            //     id: this.id,
            //     axisX: this.attrs.axisX
            // }, 'axisX'])
            // this.updateAttrElement([{
            //     id: this.id,
            //     axisY: this.attrs.axisY
            // }, 'axisY'])
            // let thisElement = this
            // let elemTemp = this.getSelectElement
            // if (elemTemp.id == this.id) {
            //     this.loadAttr()
            // }
            // setTimeout(() => { thisElement.isDragging = false }, 200)
            this.$emit("hoverParent", false)
            this.updateAttrElement([{
                id: this.id,
                axisX: this.attrs.axisX
            }, 'axisX'])
            this.updateAttrElement([{
                id: this.id,
                axisY: this.attrs.axisY
            }, 'axisY'])
            let thisElement = this
            let elemTemp = this.getSelectElement
            if (elemTemp.id == this.id) { 
                this.loadAttr()
            }
            setTimeout(() => { thisElement.isDragging = false }, 200)
        },
        ...mapMutations([
            'setIsContainer',
            'addListParent',
            'removeListParent',
            'addElements2ListParent',
            'removeElements2ListParent',
            'setSelectElement',
            'removeSelectElement',
            'addList2Add',
            'removeList2Add',
            'addElements2List2Add',
            'removeElements2List2Add',
            'setAttrElement',
            'removeAttrElement',
            'updateAttrElement'
        ])
    },
    mounted: function () {
        if(!this.attrs.isPublish) {
            var parent = this.$el.parentNode
            var element = this.$el
            var elementThis = this
            if (element) {
                interact(element)
                    .resizable({
                        edges: { left: true, right: true, bottom: true, top: true },
                        listeners: {
                            move(event) {   
                                elementThis.isSizing = true
                                var target = event.target
                                target.style.width = event.rect.width + 'px'
                                target.style.height = event.rect.height + 'px'
                            }
                        },
                        onend: function (event) {
                            var target = event.target
                            let widthPercentage = ( 100 * event.rect.width) / parent.clientWidth

                            target.style.width = widthPercentage + '%'
                            elementThis.updateAttrElement([{
                                id: elementThis.id,
                                widthElem: widthPercentage + '%'
                            }, 'widthElem'])
                            elementThis.updateAttrElement([{
                                id: elementThis.id,
                                heightElem: event.rect.height
                            }, 'heightElem'])
                            let elemTemp = elementThis.getSelectElement
                            if (elemTemp.id == elementThis.id) { 
                                elementThis.loadAttr()
                            }
                            setTimeout(() => { elementThis.isSizing = false }, 200)
                        },
                        modifiers: [
                            interact.modifiers.restrictEdges({
                                outer: element.parentNode,
                            }),
                            interact.modifiers.restrictSize({
                                min: { width: 100, height: 50 }
                            })
                        ],
                        inertia: true
                    })
                    .draggable({
                        modifiers: [
                            interact.modifiers.snap({
                                targets: [
                                    interact.snappers.grid({ x: 10, y: 10 })
                                ],
                                range: Infinity,
                                relativePoints: [{ x: 0, y: 0 }]
                            }),
                            interact.modifiers.restrict({
                                restriction: element.parentNode,
                                elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
                                endOnly: true
                            })
                        ],
                        inertia: true
                    })
                    .on('dragmove', this.dragMove)
                    .on('dragend', this.dragEnd)
            }
        }
    },
    computed: {
        translate() {
            let ejeX = 0
            let ejeY = 0
            if(isNaN(this.attrs.axisX)) {
                ejeX = this.attrs.axisX
            } else {
                ejeX = this.attrs.axisX + 'px'
            }
            if(isNaN(this.attrs.axisY)) {
                ejeY = this.attrs.axisY
            } else {
                ejeY = this.attrs.axisY + 'px'
            }
            return `${ejeX} ${ejeY}`
        },
        ...mapGetters([
            'getSelectElement',
            'getListElement',
            'getIsContainer',
            'getListElement2Add',
            'getAttrElement',
        ])
    },
}
</script>