import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#282525', 
          secondary: '#001ca0', 
          soft: '#00b7fd', 
          accent: '#e86000', 
          original: '#5b5656', 
        },
      },
    },
});
