var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.attrs.className != null ? _vm.attrs.className : ''],style:([
    _vm.translate != null ? { translate: _vm.translate } : '',
    _vm.attrs.heightElem != null ? !isNaN(_vm.attrs.heightElem) ? { height: _vm.attrs.heightElem + 'px' }  : { height: _vm.attrs.heightElem } : '', 
    _vm.attrs.widthElem != null ? !isNaN(_vm.attrs.widthElem) ? { width: _vm.attrs.widthElem + 'px' }  : { width: _vm.attrs.widthElem } : '',
    {position: 'absolute'},
]),on:{"click":function($event){return _vm.clickElement($event)}}},[_c('input',{style:([
    _vm.attrs.styleTypeFont != null ? { fontFamily: _vm.attrs.styleTypeFont } : '',
    _vm.attrs.styleWeightFont != null ? { fontWeight: _vm.attrs.styleWeightFont } : '', 
    _vm.attrs.styleSizeFont != null ? { fontSize: _vm.attrs.styleSizeFont } : '', 
    _vm.attrs.styleColorBackground != null ? { backgroundColor: _vm.attrs.styleColorBackground } : '', 
    _vm.attrs.styleColorText != null ? { color: _vm.attrs.styleColorText } : '', 
    _vm.attrs.justifyContent != null ? { textAlign: _vm.attrs.justifyContent } : '', 
    _vm.attrs.isPublish ? {  pointerEvents: 'all' } : { pointerEvents: 'none' }
]),attrs:{"type":"text","placeholder":_vm.attrs.text[_vm.attrs.isLang ? _vm.attrs.isLang : 0],"id":_vm.id}})])
}
var staticRenderFns = []

export { render, staticRenderFns }