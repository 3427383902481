<template>
    <v-main>
        <v-container>
            <v-row>
                <v-col cols="12" class="mt-5 mb-5 align-center d-flex justify-start">
                    <v-fab-transition>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="accent" dark fab @click="loadDialog()" v-bind="attrs" v-on="on">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span class="font-12">Crear Business</span>
                        </v-tooltip>
                    </v-fab-transition>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" v-for="item in listBusiness" v-bind:key="item.id">
                    <v-card color="aceent" dark>

                        <v-list-item three-line>
                            <v-list-item-content>
                                <div class="text-overline mb-4">
                                    Negocio
                                </div>
                                <v-list-item-title class="text-h5 mb-1">
                                    {{ item.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle><v-chip small class="mt-2"
                                        :color="item.status == 'develop' ? 'teal accent-3' : 'green accent-3'">
                                        {{ item.status == 'develop' ? 'En Desarrollo' : 'Desplegado' }}
                                    </v-chip></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-avatar tile size="120">
                                <v-img contain :src="item.logo"></v-img>
                            </v-list-item-avatar>
                        </v-list-item>

                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn :color="item.status == 'develop' ? 'green accent-3' : 'red accent-3'" icon
                                        @click="deployOrStop(item.id)">
                                        <v-icon dark v-bind="attrs" v-on="on">
                                            {{ item.status == 'develop' ? 'mdi-play' : 'mdi-stop' }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span class="font-12"> {{ item.status == 'develop' ? 'Continuar (Pruebas)' : `Detener
                                    (Pruebas)`
                                }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn :color="item.status2 == 'develop' ? 'green accent-3' : 'red accent-3'" icon
                                        @click="deployOrStop(item.id, 'prod')">
                                        <v-icon dark v-bind="attrs" v-on="on">
                                            {{ item.status2 == 'develop' ? 'mdi-play-circle' : 'mdi-stop-circle' }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span class="font-12"> {{ item.status2 == 'develop' ? 'Continuar (Producción)' : `Detener
                                    (Producción)`
                                }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="accent" icon :to="'/pg/' + idSite + '/' + item.id + '/' + item.url"
                                        target="_blank">
                                        <v-icon dark v-bind="attrs" v-on="on">
                                            mdi-web
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span class="font-12">Visitar web (Pruebas)</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="accent" icon
                                        :href="(item.domain != null && item.domain != '') ? item.domain : item.domain_appengine"
                                        target="_blank">
                                        <v-icon dark v-bind="attrs" v-on="on">
                                            mdi-send
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span class="font-12">Visitar web (Producción)</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="white" icon @click="loadDialog(item.id)">
                                        <v-icon dark v-bind="attrs" v-on="on">
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span class="font-12">Editar</span>
                            </v-tooltip>



                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="red" icon @click="loadDialogDelete(item.id)">
                                        <v-icon dark v-bind="attrs" v-on="on">
                                            mdi-delete-outline
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span class="font-12">Eliminar Negocio</span>
                            </v-tooltip>
                        </v-card-actions>

                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <Overlay :overlay="overlay"></Overlay>
        <Dialog :dialog="dialog" :structureTable="structureBusiness" :stateName="nameBusiness" @saveData="saveData">
        </Dialog>
        <DialogMsg :dialogDelete="dialogDelete" :stateName="nameBusiness" @confirmDelete="confirmDelete"></DialogMsg>
        <!-- <DialogConfig :dialogSettings="dialogSettings" :structureTable="structureSetting" :stateName="nameBusiness" @saveConfig="saveConfig"></DialogConfig> -->
    </v-main>
</template>

<script>
import vuetify from '../plugins/vuetify'
import Overlay from '../components/Overlay.vue'
import Dialog from '../components/Dialog.vue'
import DialogMsg from '../components/DialogMsg.vue'
// import DialogConfig from '../components/DialogConfig.vue'
import { mapState } from 'vuex'

export default {
    name: 'Business',
    vuetify,
    data: () => ({
        listBusiness: [],
        idSite: null,
        idSelected: null,
        nameBusiness: null,
        overlay: false,
        dialog: false,
        dialogDelete: false,
        dialogSettings: false,
        structureBusiness: [
            {
                "value": "id",
                "type": "string",
                "text": "Id",
                "required": false,
                "show": false,
                "data": "",
            },
            {
                "value": "name",
                "type": "string",
                "text": "Nombre",
                "required": true,
                "show": true,
                "data": "",
            },
            {
                "value": "logo",
                "type": "string",
                "text": "Logo",
                "required": true,
                "show": true,
                "data": "",
            },
            {
                "value": "favicon",
                "type": "string",
                "text": "Favicon",
                "required": true,
                "show": true,
                "data": "",
            },
            {
                "value": "logo_auth_up",
                "type": "string",
                "text": "Logo autenticación superior",
                "required": true,
                "show": true,
                "data": "",
            },
            {
                "value": "logo_auth_down",
                "type": "string",
                "text": "Logo autenticación inferior",
                "required": true,
                "show": true,
                "data": "",
            },
            {
                "value": "style",
                "type": "string",
                "text": "Estilo",
                "required": false,
                "show": true,
                "data": "",
            },
            {
                "value": "font",
                "type": "select",
                "text": "Fuente",
                "required": false,
                "show": true,
                "data": "",
                "options": [
                    {
                        text: "American Typewriter",
                        value: 'American Typewriter, serif'
                    },
                    {
                        text: "Andalé Mono",
                        value: 'Andalé Mono, monospace'
                    },
                    {
                        text: "Arial",
                        value: 'Arial, sans-serif'
                    },
                    {
                        text: "Arial Black",
                        value: 'Arial Black, sans-serif'
                    },
                    {
                        text: "Bradley Hand",
                        value: 'Bradley Hand, cursive'
                    },
                    {
                        text: "Courier",
                        value: 'Courier, monospace'
                    },
                    {
                        text: "Comfortaa",
                        value: 'Comfortaa'
                    },
                    {
                        text: "Didot",
                        value: 'Didot, serif'
                    },
                    {
                        text: "DM Sans",
                        value: 'DM Sans'
                    },
                    {
                        text: "Josefin Sans",
                        value: 'Josefin Sans'
                    },
                    {
                        text: "Georgia",
                        value: 'Georgia, serif'
                    },
                    {
                        text: "Impact",
                        value: 'Impact, sans-serif, serif'
                    },
                    {
                        text: "Lucida Console",
                        value: 'Lucida Console, monospace'
                    },
                    {
                        text: "Luminari",
                        value: 'Luminari, fantasy'
                    },
                    {
                        text: "Poppins",
                        value: 'Poppins'
                    },
                    {
                        text: "Tahoma",
                        value: 'Tahoma, sans-serif'
                    },
                    {
                        text: "Times New Roman",
                        value: 'Times New Roman, serif'
                    },
                    {
                        text: "Trebuchet MS",
                        value: 'Trebuchet MS, sans-serif'
                    },
                    {
                        text: "Verdana",
                        value: 'Verdana, sans-serif'
                    },
                    {
                        text: "Work Sans",
                        value: 'Work Sans'
                    }
                ]
            },
            {
                "value": "domain",
                "type": "string",
                "text": "Dominio",
                "required": false,
                "show": true,
                "data": "",
            },
        ],
    }),
    components: {
        Overlay,
        Dialog,
        // DialogConfig,
        DialogMsg
    },
    computed: {
        ...mapState([
            'urlProd',
            'urlTest',
            'isProd',
            'isLogin',
            'userInit'
        ]),
    },
    methods: {
        loadDialog(id) {
            if (id != null) {
                this.idSelected = id
                for (let i = 0; i < this.listBusiness.length; i++) {
                    if (this.listBusiness[i].id == id) {
                        for (let j = 0; j < Object.keys(this.listBusiness[i]).length; j++) {
                            for (let k = 0; k < this.structureBusiness.length; k++) {
                                if (this.structureBusiness[k]["value"] == Object.keys(this.listBusiness[i])[j]) {
                                    this.structureBusiness[k]["data"] = this.listBusiness[i][Object.keys(this.listBusiness[i])[j]]
                                    // if (this.structureBusiness[k]["type"] == 'select') {
                                    //     this.structureBusiness[k]["data"] = this.listBusiness[i][Object.keys(this.listBusiness[i])[j]].split(',')
                                    // } else {
                                    // }
                                }
                            }
                        }
                        this.nameBusiness = this.listBusiness[i].name
                        break;
                    }
                }
            } else {
                for (let index = 0; index < this.structureBusiness.length; index++) {
                    this.structureBusiness[index]["data"] = '';
                }
            }
            this.dialog = true
        },
        loadDialogDelete(id) {
            if (id != null) {
                this.idSelected = id
                for (let i = 0; i < this.listBusiness.length; i++) {
                    if (this.listBusiness[i].id == id) {
                        this.nameBusiness = this.listBusiness[i].name
                        break;
                    }
                }
            }
            this.dialogDelete = true
        },
        async deployOrStop(id, environment = 'test') {
            if (id != null) {
                this.overlay = true
                this.idSelected = id
                for (let i = 0; i < this.listBusiness.length; i++) {
                    if (this.listBusiness[i].id == id) {
                        var data = {}
                        data['id_site'] = this.idSite
                        data['id'] = this.idSelected
                        if (environment == 'test') {
                            if (this.listBusiness[i].status == 'develop') {
                                data['status'] = 'deploy'
                            } else {
                                data['status'] = 'develop'
                            }
                        } else if (environment == 'prod') {
                            if (this.listBusiness[i].status2 == 'develop') {
                                data['status'] = 'deploy'
                            } else {
                                data['status'] = 'develop'
                            }
                        }
                        data['environment'] = environment;
                        let url = this.isProd ? this.urlProd + 'api/business/update-status' : this.urlTest + 'api/business/update-status'
                        let result = await fetch(url, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(data)
                        }).then((res) => res.json())
                        if (result.success && result.code == 200) {
                            let result = await fetch(this.isProd ? this.urlProd + 'api/business/get/' + this.$route.params.id : this.urlTest + 'api/business/get/' + this.$route.params.id).then((res) => res.json())
                            this.overlay = false
                            if (result.success && result.code == 200) {
                                this.listBusiness = result.data
                            }
                        } else {
                            console.log('Ocurrio un error', result);
                        }
                    }
                }
            }
        },
        async saveData(e) {
            if (e == null) {
                this.dialog = false
            } else {
                console.log(e);
                this.dialog = false
                var data = {}
                for (let i = 0; i < e.length; i++) {
                    // if (e[i]['data'] != '') {
                    if (Array.isArray(e[i]['data'])) {
                        data[e[i]['value']] = e[i]['data'].join(',')
                    } else {
                        data[e[i]['value']] = e[i]['data']
                    }
                    // }
                }
                data['id_site'] = this.idSite
                this.overlay = true
                let isCreateOrUpdate = !data.id ? '/create' : '/update'
                let url = this.isProd ? this.urlProd + 'api/business' + isCreateOrUpdate : this.urlTest + 'api/business' + isCreateOrUpdate
                let result = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then((res) => res.json())
                if (result.success && result.code == 200) {
                    let result = await fetch(this.isProd ? this.urlProd + 'api/business/get/' + this.$route.params.id : this.urlTest + 'api/business/get/' + this.$route.params.id).then((res) => res.json())
                    this.overlay = false
                    if (result.success && result.code == 200) {
                        this.listBusiness = result.data
                    }
                } else {
                    console.log('Ocurrio un error', result);
                }
            }
        },
        async confirmDelete(e) {
            if (e) {
                this.dialogDelete = false
                var data = {
                    id_site: this.idSite,
                    id: this.idSelected
                }
                this.overlay = true
                let url = this.isProd ? this.urlProd + 'api/business/delete' : this.urlTest + 'api/business/delete'
                let result = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then((res) => res.json())
                if (result.success && result.code == 200) {
                    let result = await fetch(this.isProd ? this.urlProd + 'api/business/get/' + this.$route.params.id : this.urlTest + 'api/business/get/' + this.$route.params.id).then((res) => res.json())
                    this.overlay = false
                    if (result.success && result.code == 200) {
                        this.listBusiness = result.data
                    }
                } else {
                    console.log('Ocurrio un error', result);
                }
            } else {
                this.dialogDelete = false
            }
        },
    },
    created: async function () {
        this.overlay = true
        this.idSite = this.$route.params.id
        let result = await fetch(this.isProd ? this.urlProd + 'api/business/get/' + this.$route.params.id : this.urlTest + 'api/business/get/' + this.$route.params.id).then((res) => res.json())
        console.log(result);
        this.overlay = false
        if (result.success && result.code == 200) {
            this.listBusiness = result.data
        }
    },
    metaInfo() {
        return {
            title: `${this.$route.name}`,
            // meta: [
            //   { name: 'description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline },
            //   { property: 'og:title', content: this.userData.name + ' - Epiloge' },
            //   { property: 'og:site_name', content: 'Epiloge' },
            //   { property: 'og:description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline },
            //   { property: 'og:type', content: 'profile' },
            //   { property: 'og:url', content: 'https://epiloge.com/@' + this.userData.username },
            //   { property: 'og:image', content: this.aws_url + '/users/' + this.userData.profileurl + '-main.jpg' }
            // ]
        }
    },
}
</script>