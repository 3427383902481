<template>
    <v-dialog v-model="dialog" persistent max-width="40%">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ stateName }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col v-for="item in filterStructure" cols="12" md="6" :title="item.text" v-bind:key="item.id">
                            <template v-if="item.type == 'string'">
                                <v-text-field v-if="item.value != 'descripcion' && item.value != 'email'"
                                    :label="item.text" v-model="item.data"
                                    :required="item.required"></v-text-field>
                                <v-text-field v-if="item.value == 'email'" :rules="emailRules" name="input-7-1"
                                    :label="item.text" v-model="item.data"
                                    :required="item.required">
                                </v-text-field>
                            </template>
                            <template v-if="item.type == 'integer'">
                                <v-text-field :label="item.text"
                                    v-if="item.value != 'celular'" v-model="item.data" type="number"
                                    :required="item.required" :disabled="item.text == 'Id'"></v-text-field>
                                <v-text-field v-if="item.value == 'celular'" :rules="phoneRules" name="input-7-1"
                                    :label="item.text" v-model="item.data"
                                    :required="item.required">
                                </v-text-field>
                            </template>
                            <template v-if="item.type == 'double'">
                                <v-text-field :label="item.text" v-model="item.data"
                                    type="number" :required="item.required"></v-text-field>
                            </template>
                            <template v-if="item.type == 'select'">
                                <v-autocomplete v-model="item.data" :label="item.text"
                                    :items="item.options" :item-text="text" :item-value="value" :multiple="item.multiple">
                                </v-autocomplete>
                            </template>
                            <template v-if="item.type == 'boolean'">
                                <v-checkbox v-model="item.data" :label="item.text"></v-checkbox>
                            </template>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="saveData(null)" class="mr-4">
                    Cerrar
                </v-btn>
                <v-btn color="primary" text @click="saveData(structureTable)">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import vuetify from '../plugins/vuetify'

export default {
    vuetify,
    props: ['dialog', 'structureTable', 'stateName'],
    data: () => ({
        basicRules: [(v) => !!v || "Es obligatorio"],
        emailRules: [
            (v) => {
                const pattern =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(v) || "Debe ser un correo valido";
            },
        ],
        phoneRules: [
            (v) => !!v || "Es obligatorio",
            (v) => {
                const pattern = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
                return pattern.test(v) || "Debe ser un telefono valido";
            },
        ],
    }),
    computed: {
        filterStructure () {
            return this.structureTable.filter(e => e.show)
        }
    },
    methods: {
        clearData() {
            for (let index = 0; index < this.structureTable.length; index++) {
                this.structureTable[index]["data"] = '';
            }
        },
        saveData(result) {
            if(result == null) {
                this.clearData()
            }
            this.$emit("saveData", result)
        }
    }
}
</script>